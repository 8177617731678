import React from 'react';
import { withNamespaces } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { pageView } from '../analytics';
import { setPageAsLoadedDispatch } from '../redux/actions/global-actions';
import Seo from '../components/Seo';

/**
 * Container to render the Privacy Policy page
 */
class Privacy extends React.Component {
  componentDidMount() {
    setPageAsLoadedDispatch();
    document.body.classList.add('body-grey');
    pageView(window.location.pathname);

    window.addEventListener('resize', this.onResize.bind(this), false);

    return () => {
      document.body.classList.remove('body-grey');
    };
  }

  scrollBarRef = null;

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    const { onUpdateSize } = this.props;
    onUpdateSize();
  }


  render() {
    const { t } = this.props;

    return (
      <PerfectScrollbar ref={(ref) => { this.scrollBarRef = ref; }}>
        <div className="panel-group">
          <Seo
            title={t('seo.privacyPolicy.title')}
            description={t('seo.privacyPolicy.description')}
            keywords={t('seo.genericKeywords')}
          />
          <div className="col-md-12 no-padding">
            <div className="panel page-text">
              <h1>{t('privacyPage.title')}</h1>
              <p>{t('privacyPage.date')}</p>
              <div className="wrapper-text mt10">
                <p>{t('privacyPage.desc1_1')} <a href="https://www.epidemictracker.com">https://www.epidemictracker.com</a> {t('privacyPage.desc1_2')}</p>
                <p>{t('privacyPage.desc2')}</p>
                <p>{t('privacyPage.desc3')} <a href="https://www.epidemictracker.com">https://www.epidemictracker.com</a>.</p>
              </div>

              <h1 className="mb10">{t('privacyPage.subtitle2')}</h1>
              <h2>{t('privacyPage.service')}</h2>
              <p>{t('privacyPage.service1')} <a href="https://www.epidemictracker.com">https://www.epidemictracker.com</a> {t('privacyPage.service2')}.</p>

              <h2 className="mt10">{t('privacyPage.personalData')}</h2>
              <p>{t('privacyPage.personalDataText')}</p>

              <h2 className="mt10">{t('privacyPage.usageData')}</h2>
              <p>{t('privacyPage.usageDataText')}</p>

              <h2 className="mt10">{t('privacyPage.cookie')}</h2>
              <p>{t('privacyPage.cookieText')}</p>

              <h2 className="mt10">{t('privacyPage.dataController')}</h2>
              <p>{t('privacyPage.dataController1')}</p>
              <p>{t('privacyPage.dataController2')}</p>

              <h2 className="mt10">{t('privacyPage.dataProcessors')}</h2>
              <p>{t('privacyPage.dataProcessors1')}</p>
              <p>{t('privacyPage.dataProcessors2')}</p>

              <h2 className="mt10">{t('privacyPage.dataSubject')}</h2>
              <p>{t('privacyPage.dataSubjectText')}</p>

              <h1 className="mt20">{t('privacyPage.informationCollection')}</h1>
              <p>{t('privacyPage.informationCollectionText')}</p>

              <h2 className="mt10">{t('privacyPage.typeDataCollected')}</h2>
              <p>{t('privacyPage.typeDataCollectedText')}</p>

              <h2 className="mt10">{t('privacyPage.personalData')}</h2>
              <p>{t('privacyPage.personalDataText2')}</p>
              <p>&bull; {t('privacyPage.emailAddress')}</p>
              <p>&bull; {t('privacyPage.firstNameAndLastName')}</p>
              <p>&bull; {t('privacyPage.phoneNumber')}</p>
              <p>&bull; {t('privacyPage.cookiesAndUsageData')}</p>

              <h2 className="mt10">{t('privacyPage.usageDataUsageData')}</h2>
              <p>{t('privacyPage.usageDataUsageDataText')}</p>

              <h2 className="mt10">{t('privacyPage.trackingCookiesData')}</h2>
              <p>{t('privacyPage.trackingCookiesDataText1')}</p>
              <p>{t('privacyPage.trackingCookiesDataText2')}</p>
              <p>{t('privacyPage.trackingCookiesDataText3')}</p>
              <p>&bull; <span className="semi-bold">{t('privacyPage.trackingCookiesDataItem1')}</span> {t('privacyPage.trackingCookiesDataItem1_1')}</p>
              <p>&bull; <span className="semi-bold">{t('privacyPage.trackingCookiesDataItem2')}</span> {t('privacyPage.trackingCookiesDataItem2_2')}</p>
              <p>&bull; <span className="semi-bold">{t('privacyPage.trackingCookiesDataItem3')}</span> {t('privacyPage.trackingCookiesDataItem3_3')}</p>

              <h1 className="mt20">{t('privacyPage.useofData')}</h1>
              <p>{t('privacyPage.useofDataText')}</p>
              <p>&bull; {t('privacyPage.useOfDataItem1')}</p>
              <p>&bull; {t('privacyPage.useOfDataItem2')}</p>
              <p>&bull; {t('privacyPage.useOfDataItem3')}</p>
              <p>&bull; {t('privacyPage.useOfDataItem4')}</p>
              <p>&bull; {t('privacyPage.useOfDataItem5')}</p>
              <p>&bull; {t('privacyPage.useOfDataItem6')}</p>
              <p>&bull; {t('privacyPage.useOfDataItem7')}</p>

              <h1 className="mt20">{t('privacyPage.legalBasis')}</h1>
              <p>{t('privacyPage.legalBasisText')}</p>
              <p>{t('privacyPage.legalBasisList')}</p>
              <p>&bull; {t('privacyPage.legalBasisItem1')}</p>
              <p>&bull; {t('privacyPage.legalBasisItem2')}</p>
              <p>&bull; {t('privacyPage.legalBasisItem3')}</p>
              <p>&bull; {t('privacyPage.legalBasisItem4')}</p>

              <h1 className="mt20">{t('privacyPage.retentionofData')}</h1>
              <p>{t('privacyPage.retentionofDataText1')}</p>
              <p>{t('privacyPage.retentionofDataText2')}</p>

              <h1 className="mt20">{t('privacyPage.transferOfData')}</h1>
              <p>{t('privacyPage.transferOfDataText1')}</p>
              <p>{t('privacyPage.transferOfDataText2')}</p>
              <p>{t('privacyPage.transferOfDataText3')}</p>
              <p>{t('privacyPage.transferOfDataText4')}</p>

              <h1 className="mt20">{t('privacyPage.disclosureOfData')}</h1>
              <h2 className="mt10">{t('privacyPage.legalRequirements')}</h2>
              <p>{t('privacyPage.legalRequirementsList')}</p>
              <p>&bull; {t('privacyPage.legalRequirementsItem1')}</p>
              <p>&bull; {t('privacyPage.legalRequirementsItem2')}</p>
              <p>&bull; {t('privacyPage.legalRequirementsItem3')}</p>
              <p>&bull; {t('privacyPage.legalRequirementsItem4')}</p>
              <p>&bull; {t('privacyPage.legalRequirementsItem5')}</p>

              <h1 className="mt20">{t('privacyPage.securityOfData')}</h1>
              <p>{t('privacyPage.securityOfDataText')}</p>

              <h1 className="mt20">{t('privacyPage.dataGDPR')}</h1>
              <p>{t('privacyPage.dataGDPR1')}</p>
              <p>{t('privacyPage.dataGDPR2')}</p>
              <p>{t('privacyPage.dataGDPR3')}</p>
              <p><span className="semi-bold">{t('privacyPage.dataGDPR4')}</span> {t('privacyPage.dataGDPR4_1')}</p>
              <p><span className="semi-bold">{t('privacyPage.dataGDPR5')}</span> {t('privacyPage.dataGDPR5_1')}</p>
              <p><span className="semi-bold">{t('privacyPage.dataGDPR6')}</span> {t('privacyPage.dataGDPR6_1')}</p>
              <p><span className="semi-bold">{t('privacyPage.dataGDPR7')}</span> {t('privacyPage.dataGDPR7_1')}</p>
              <p><span className="semi-bold">{t('privacyPage.dataGDPR8')}</span> {t('privacyPage.dataGDPR8_1')}</p>
              <p><span className="semi-bold">{t('privacyPage.dataGDPR9')}</span> {t('privacyPage.dataGDPR9_1')}</p>
              <p>{t('privacyPage.dataGDPR10')}</p>
              <p>{t('privacyPage.dataGDPR11')}</p>

              <h1 className="mt20">{t('privacyPage.serviceProviders')}</h1>
              <p>{t('privacyPage.serviceProvidersText')}</p>

              <h2 className="mt10">{t('privacyPage.analytics')}</h2>
              <p>{t('privacyPage.analyticsText')}</p>
              <p>&bull; <span className="semi-bold">{t('privacyPage.gAnalytics')}</span></p>
              <p>&emsp; {t('privacyPage.gAnalyticsText')} <a href="https://www.google.com/analytics/terms/us.html" rel="noopener noreferrer" target="_blank">https://www.google.com/analytics/terms/us.html</a></p>
              <p>&bull; <span className="semi-bold">{t('privacyPage.hotjar')}</span></p>
              <p>&emsp; {t('privacyPage.hotjarText')} <a href="https://www.hotjar.com/legal/policies/privacy" rel="noopener noreferrer" target="_blank">https://www.hotjar.com/legal/policies/privacy</a></p>

              <h1 className="mt20">{t('privacyPage.linksToOtherSites')}</h1>
              <p>{t('privacyPage.linksToOtherSitesText')}</p>

              <h1 className="mt20">{t('privacyPage.childrensPrivacy')}</h1>
              <p>{t('privacyPage.childrensPrivacyText')}</p>
              <p>{t('privacyPage.childrensPrivacyText2')}</p>

              <h1 className="mt20">{t('privacyPage.changesToThisPrivacyPolicy')}</h1>
              <p>{t('privacyPage.changesToThisPrivacyPolicyText')}</p>
              <p>{t('privacyPage.changesToThisPrivacyPolicyText2')}</p>
              <p>{t('privacyPage.changesToThisPrivacyPolicyText3')}</p>

              <h1 className="mt20">{t('privacyPage.contactUs')}</h1>
              <p>{t('privacyPage.contactUsText')}</p>
              <p>&bull; {t('privacyPage.mail')} <a href="mailto:info@metabiota.com">info@metabiota.com</a></p>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    );
  }
}

export default withNamespaces()(Privacy);
