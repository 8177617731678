import React from 'react';
import { withNamespaces } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { pageView } from '../analytics';
import history from '../history';
import { setPageAsLoadedDispatch } from '../redux/actions/global-actions';
import Seo from '../components/Seo';

/**
 * Container to render the Terms and Conditions page
 */
class Terms extends React.Component {
  componentDidMount() {
    setPageAsLoadedDispatch();
    document.body.classList.add('body-grey');
    pageView(window.location.pathname);

    window.addEventListener('resize', this.onResize.bind(this), false);

    return () => {
      document.body.classList.remove('body-grey');
    };
  }

  scrollBarRef = null;

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  goToPrivacy = () => {
    history.push('/privacy');
  };

  onResize() {
    const { onUpdateSize } = this.props;
    onUpdateSize();
  }

  render() {
    const { t } = this.props;

    return (
      <PerfectScrollbar
        ref={(ref) => {
          this.scrollBarRef = ref;
        }}
      >
        <div className="panel-group">
          <Seo
            title={t('seo.termsAndConditions.title')}
            description={t('seo.termsAndConditions.description')}
            keywords={t('seo.genericKeywords')}
          />
          <div className="col-md-12 no-padding">
            <div className="panel page-text">
              <h1>{t('termsPage.title')}</h1>
              <h1 className="mt10">{t('termsPage.effectiveDate')}</h1>
              <p className="mt20">
                {t('termsPage.termsText')}
                <a
                  href="https://www.ginkgobiosecurity.com/"
                  className="btn-link-default"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ginkgobiosecurity.com
                </a>
                {t('termsPage.termsText2')}
                <a
                  href="https://www.ginkgobiosecurity.com/privacy-policy"
                  className="btn-link-default"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ginkgobiosecurity.com/privacy-policy
                </a>
                {t('termsPage.termsText3')}
              </p>
              <h1 className="mt20">{t('termsPage.purpose')}</h1>
              <p className="mt10">{t('termsPage.purposeText')}</p>
              <h1 className="mt20">{t('termsPage.acceptableUse')}</h1>
              <p className="mt10">{t('termsPage.acceptableUseText')}</p>
              <h1 className="mt20">{t('termsPage.disclaimers')}</h1>
              <p className="mt10">{t('termsPage.disclaimersText')}</p>
              <p className="mt10">{t('termsPage.disclaimersText2')}</p>
              <p className="mt10">{t('termsPage.disclaimersText3')}</p>
              <p className="mt10">{t('termsPage.disclaimersText4')}</p>
              <p className="mt10">{t('termsPage.disclaimersText5')}</p>
              <h1 className="mt20">{t('termsPage.limitationsOfLiability')}</h1>
              <p className="mt10">{t('termsPage.limitationsOfLiabilityText')}</p>
              <h1 className="mt20">{t('termsPage.intellectualProperty')}</h1>
              <p className="mt10">{t('termsPage.intellectualPropertyText1')}</p>
              <p className="mt10">{t('termsPage.intellectualPropertyText2')}</p>
              <p className="mt10">
                {t('termsPage.intellectualPropertyText31')}
                <a
                  href="https://www.ginkgobiosecurity.com/privacy-policy/"
                  className="btn-link-default"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ginkgobiosecurity.com/privacy-policy
                </a>
                {t('termsPage.intellectualPropertyText32')}
              </p>
              <h1 className="mt20">{t('termsPage.indemnification')}</h1>
              <p className="mt10">{t('termsPage.indemnificationText')}</p>
              <h1 className="mt20">{t('termsPage.children')}</h1>
              <p className="mt10">{t('termsPage.childrenText')}</p>
              <h1 className="mt20">{t('termsPage.links')}</h1>
              <p className="mt10">{t('termsPage.linksText')}</p>
              <h1 className="mt20">{t('termsPage.changes')}</h1>
              <p className="mt10">{t('termsPage.changesText')}</p>
              <h1 className="mt20">{t('termsPage.governingLaw')}</h1>
              <p className="mt10">{t('termsPage.governingLawText1')}</p>
              <p className="mt10">{t('termsPage.governingLawText2')}</p>
              <p className="mt10">{t('termsPage.governingLawText3')}</p>
              <h1 className="mt20">{t('termsPage.generalTerms')}</h1>
              <p className="mt10">{t('termsPage.generalTermsText')}</p>

              {/* <h1 className="mt20">{t('termsPage.contactUs')}</h1> */}
              {/* <p>{t('termsPage.contactText')}</p> */}
              {/* <p>{t('termsPage.company')}</p> */}
              {/* <p>{t('termsPage.address')}</p> */}
              {/* <p>{t('termsPage.state')}</p> */}
              {/* <p>{t('termsPage.country')}</p> */}
              {/* <p>{t('termsPage.phone')}</p> */}
              {/* <a href="mailto:info@metabiota.com">info@metabiota.com</a> */}
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    );
  }
}

export default withNamespaces()(Terms);
