import axios from 'axios';
import store from '../store';
import { setPageLoading, setPageError, setPageAsLoaded } from './global-actions';
import { setPathogens } from '../../utils';

export const setDetailsLoading = loading => store.dispatch({
  type: loading ? 'PATHOGEN_DETAILS_LOADING' : 'PATHOGEN_DETAILS_LOADED',
});

export const setSelectedPathogenProfile = pathogenName => (dispatch) => {
  setDetailsLoading(true);

  return axios.get(`/pathogens/${encodeURIComponent(pathogenName)}`)
    .then((response) => {
      dispatch({
        type: 'PATHOGEN_PROFILE_SELECTED_PATHOGEN_PROFILE',
        pathogenDetails: response.data,
      });
      setDetailsLoading(false);
    })
    .catch(() => {
      dispatch({
        type: 'PATHOGEN_PROFILE_SELECTED_PATHOGEN_PROFILE',
        pathogenDetails: false,
      });
      setDetailsLoading(false);
      dispatch(setPageError());
    });
};

export const clearSelectedPathogenProfile = () => {
  store.dispatch({
    type: 'PATHOGEN_PROFILE_SELECTED_PATHOGEN_PROFILE',
    pathogenDetails: false,
  });
};

export const setPathogensData = data => ({
  type: 'SET_PATHOGENS_DATA',
  data,
});

export const fetchPathogens = () => (dispatch) => {
  setPageLoading(true);
  return axios.get('/pathogens')
    .then((response) => {
      const resp = response.data;
      const allPathogen = { value: null, label: 'All Pathogens' };
      const parsedOptions = resp.map((item, i) => {
        const objMerged = {
          index: i,
          value: item.pathogenName,
          label: item.pathogenName,
          isDisabled: !item.isNRT,
        };
        return {
          ...item,
          ...objMerged,
        };
      });
      parsedOptions.unshift(allPathogen);
      // update the list used to check against url right after retrieved, not later
      setPathogens(parsedOptions);
      dispatch(setPathogensData(parsedOptions));
      setPageLoading(false);
    })
    .catch(() => {
      dispatch(setPageAsLoaded());
      dispatch(setPageError());
      setPageLoading(false);
    });
};

export const resetPathogen = () => {
  store.dispatch({
    type: 'RESET_PATHOGEN',
  });
};

export const selectPathogenFilter = (selected) => {
  store.dispatch({
    type: 'SELECT_PATHOGEN_FILTER',
    selected,
    isClearable: !!(selected && selected.value),
  });
};
