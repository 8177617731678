import React from 'react';
import { withNamespaces } from 'react-i18next';
import { TooltipMobile, TooltipDesktop } from '../../Tooltip';
import { heatMapColors } from '../MapUtils';

const HeatmapLegend = ({ t }) => (
  <div className="mb-legend-box">
    <div className="title">
      { t('mapbox.legends.heatmap.title') }
      <div className="info-box">
        <TooltipDesktop
          theme="legend"
          content={t('infoTooltip.legend.tracked_epidemics')}
          placement="top-start"
        >
          <span className="mb-icon mb-icon-info-icon" />
        </TooltipDesktop>

        <TooltipMobile
          theme="legend"
          content={t('infoTooltip.legend.tracked_epidemics')}
        >
          <span className="mb-icon mb-icon-info-icon" />
        </TooltipMobile>
      </div>
    </div>
    <div className="info-legend">
      <div>{ t('mapbox.legends.lowest') }</div>
      <div className="steps">
        { heatMapColors.default.slice(0, -1).map(color => (
          <div key={color} className="step" style={{ backgroundColor: color }} />
        )) }
      </div>
      <div>{ t('mapbox.legends.highest') }</div>
    </div>
  </div>
);

export default withNamespaces()(HeatmapLegend);
