const initialState = {
  data: null,
  loaded: false,
  selectedCountry: false,
  countryData: false,
  event: null,
  countryDataLoading: true,
  errors: {
  },
  openedPanel: false,
  popupOpened: false,
  countryModalOpened: false,
};

export default function mapReducers(state = initialState, action) {
  switch (action.type) {
    case 'SET_MAP_DATA':
      return Object.assign({}, state, {
        data: action.data,
      });
    case 'MAP_LOADED':
      return Object.assign({}, state, {
        loaded: true,
      });
    case 'MAP_NOT_LOADED':
      return Object.assign({}, state, {
        loaded: false,
      });
    case 'COUNTRY_DATA_LOADING':
      return Object.assign({}, state, { countryDataLoading: true });
    case 'SELECT_COUNTRY': {
      return Object.assign({}, state, {
        selectedCountry: action.cCode,
      });
    }
    case 'SELECTED_COUNTRY_DATA': {
      return Object.assign({}, state, { countryData: action.data });
    }
    case 'COUNTRY_DATA_LOADED':
      return Object.assign({}, state, { countryDataLoading: false });
    case 'DESELECT_COUNTRY': {
      return Object.assign({}, state, {
        selectedCountry: false,
        countryData: false,
        countryDataLoading: false,
      });
    }
    case 'OPEN_PANEL':
      return Object.assign({}, state, { openedPanel: action.panel });
    case 'CLOSE_PANEL':
      return {
        ...state,
        openedPanel: false,
      };
    case 'OPEN_POPUP':
      return {
        ...state,
        popupOpened: action.open,
      };
    case 'SET_POPUP_POSITION':
      return Object.assign({}, state, {
        event: action.event,
      });
    case 'OPEN_COUNTRY_MODAL':
      return Object.assign({}, state, { countryModalOpened: action.open });
    default:
      return state;
  }
}
