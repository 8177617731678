export default function formReducers(state = {}, action) {
  switch (action.type) {
    case 'CONTACT_FORM_LOADING':
      return Object.assign({}, state, {
        contactFormLoading: true,
      });
    case 'CONTACT_FORM_LOADED':
      return Object.assign({}, state, {
        contactFormLoading: false,
      });
    default:
      return state;
  }
}
