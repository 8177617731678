export const CAT_ONBOARDING = 'Onboarding';
export const CAT_HOME = 'Home';
export const CAT_MAP = 'Map';
export const CAT_FOOTER = 'Footer';
export const CAT_SIDEBAR = 'Sidebar';
export const CAT_CONTACT = 'Contact';
export const CAT_REPORT = 'Report';

export const WITH_EVENTS = 'with-events';
export const WITHOUT_EVENTS = 'without-events';
export const MODAL = 'modal';

export const CLICKED = 'Clicked';
export const SELECT = 'Select';
export const CHANGE = 'Change';
export const RESET = 'Reset';
export const OPEN = 'Open';
export const CLOSE = 'Close';
export const CLICK = 'Click';
export const CLICK_ON_BUTTON = 'Click On Button';
