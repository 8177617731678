import React from 'react';
import { withNamespaces } from 'react-i18next';
import { TooltipDesktop, TooltipMobile } from '../components/Tooltip';

/**
 * Container to render the Metabiota Offerings modal
 */

const MetabiotaOfferings = class MetabiotaOfferings extends React.Component {
  constructor(props) {
    super(props);

    this.key = 0;
    const { t } = this.props;

    this.features = [{
      title: t('metabiotaOfferings.historic_catalogs'),
      premium: true,
      basic: false,
    }, {
      title: t('metabiotaOfferings.mpi'),
      premium: true,
      basic: false,
    }, {
      title: t('metabiotaOfferings.mss'),
      premium: true,
      basic: false,
    }, {
      title: t('metabiotaOfferings.ongoing_events'),
      premium: true,
      basic: true,
    }, {
      title: t('metabiotaOfferings.historic_events'),
      premium: true,
      basic: false,
    }, {
      title: t('metabiotaOfferings.qual_quant'),
      premium: true,
      basic: false,
    }, {
      title: t('metabiotaOfferings.pathogen_profiles'),
      premium: true,
      basic: true,
    }, {
      title: t('metabiotaOfferings.download_biweekly'),
      premium: true,
      basic: true,
    }, {
      title: t('metabiotaOfferings.custom_assets'),
      premium: true,
      basic: false,
      info: t('metabiotaOfferings.custom_assets_info'),
    }];
  }

  getUniqueKey = () => this.key += 1;

  render() {
    const { features } = this;
    const { t, noTitle } = this.props;
    return (
      <div className="metabiota-offerings">
        <div className={`panel-heading ${noTitle ? 'hide' : ''}`}>
          <span className="text-uppercase semibold-font-weight">{t('metabiotaOfferings.title')}</span>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>
                {t('metabiotaOfferings.all_features')}
              </th>
              <th>
                <div className="text-center">
                  <span className="text-uppercase">{t('metabiotaOfferings.premium')}</span><br />
                  <i className="normal-font-weight">{t('metabiotaOfferings.license')}</i>
                </div>
              </th>
              <th>
                <div className="text-center">
                  <span className="text-uppercase">{t('metabiotaOfferings.basic')}</span><br />
                  <i className="normal-font-weight">{t('metabiotaOfferings.free')}</i>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {features.map(({
              title, premium, basic, info,
            }) => (
              <tr key={this.getUniqueKey()}>
                <td>
                  { title }
                  { info
                    ? (
                      <div className="d-inline-flex valign-middle">
                        &nbsp;
                        <TooltipDesktop content={info}>
                          <span className="mb-icon mb-icon-info-icon" />
                        </TooltipDesktop>
                        <TooltipMobile content={info}>
                          <span className="mb-icon mb-icon-info-icon" />
                        </TooltipMobile>
                      </div>
                    )
                    : ''
                  }
                </td>
                <td className="text-center">
                  { premium ? <span className="mb-icon mb-icon-check" /> : '' }
                </td>
                <td className="text-center">
                  { basic ? <span className="mb-icon mb-icon-check" /> : '' }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export default withNamespaces()(MetabiotaOfferings);
