import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import pipe, { isMobileView, setAppUrl, getDataFromUrl } from '../../utils';
import { pageView, trackEvent } from '../../analytics';
import {
  selectPathogenFilter,
} from '../../redux/actions/pathogen-actions';
import { closePanel, deselectCountry } from '../../redux/actions/map-actions';
import SelectFactory from '../../components/SelectFactory/SelectFactory';
import store from '../../redux/store';

class MapFilters extends React.Component {
  triggers = 0;

  componentDidMount() {
    window.addEventListener('popstate', this.onpopstate.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onpopstate);
  }

  onpopstate() {
    if (this.triggers === 1) {
      const urlParams = getDataFromUrl();
      selectPathogenFilter(!urlParams.pathogenName
        ? null
        : { value: urlParams.pathogenName, label: urlParams.pathogenName });
    }
  }

  updateSelectedPathogenAndQueryParam = () => {
    selectPathogenFilter();
    setAppUrl('', '');
    pageView('/');
    trackEvent('CAT_SIDEBAR', {
      prefix: '',
      name: 'RESET',
      suffix: 'filter map',
    }, {
      prefix: '',
      name: 'RESET',
      suffix: 'the map filter',
    });
  };

  triggerOnSelect = (selected) => {
    this.triggers += 1;

    deselectCountry();
    if (selected) {
      if (selected.value !== this.props.pathogens[0].value) {
        selectPathogenFilter(selected);
        setAppUrl(null, selected.value, this.props.pathogens);
        pageView(`${window.location.pathname}`);
        trackEvent('CAT_SIDEBAR', {
          prefix: '',
          name: 'CHANGE',
          suffix: `filter map by ${selected.label}`,
        }, {
          prefix: '',
          name: 'SELECT',
          suffix: 'map filter by pathogen',
        });
      } else {
        this.updateSelectedPathogenAndQueryParam();
      }
    } else {
      this.updateSelectedPathogenAndQueryParam();
    }
    if (isMobileView()) {
      store.dispatch(closePanel());
    }
  };

  render() {
    const {
      t,
      pathogens,
      selectedPathogen,
      isClearable,
    } = this.props;

    const availablePathogens = pathogens
      ? pathogens.filter(pathogen => pathogen.isNRT === true || pathogen.label === 'All Pathogens')
      : {};
    const notAvailablePathogens = pathogens
      ? pathogens.filter(pathogen => pathogen.isNRT === false)
      : {};
    const groupedOptions = [
      {
        label: t('mapFilters.casesTracked'),
        options: availablePathogens,
      },
      {
        label: t('mapFilters.noCasesTracked'),
        options: notAvailablePathogens,
      },
    ];
    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };

    const labelStyles = {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#000000',
      paddingtop: '11px',
      paddingLeft: '20px',
    };

    const formatGroupLabel = data => (
      <div style={groupStyles}>
        <span style={labelStyles}>{data.label}</span>
        <span>{data.options.length}</span>
      </div>
    );

    const disabledTooltipText = t('infoTooltip.pathogens.not_available');

    return (
      <div className="map-filters-comp padding-bottom padding-left padding-right">
        <p className="sub-title extra-small-margin-bottom">{t('home.sidePanelTitles.pathogenLabel')}</p>
        <SelectFactory
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
          defaultOption={selectedPathogen}
          isSearchable={!isMobileView()}
          disabledTooltipText={disabledTooltipText}
          allPathogensOption={pathogens[0]}
          firstOptionSelectOnClear
          isClearable={isClearable}
          onUpdate={this.triggerOnSelect}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pathogens: state.pathogenReducers.pathogensList,
    selectedPathogen: state.pathogenReducers.selectedPathogen,
    isClearable: state.pathogenReducers.isClearable,
  };
}

export default pipe(withNamespaces(), connect(mapStateToProps))(MapFilters);
