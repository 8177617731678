import axios from 'axios';
import store from '../store';
import * as Constants from '../../constants';

export const setPageAsLoadedDispatch = () => store.dispatch({
  type: 'INIT_APP_COMPLETED',
});

export const setPageAsLoaded = () => ({
  type: 'INIT_APP_COMPLETED',
});

export const setPageErrorDispatch = () => store.dispatch({
  type: 'DISPLAY_ERROR_MODAL',
});

export const setPageError = () => ({
  type: 'DISPLAY_ERROR_MODAL',
});

export const hidePageError = () => store.dispatch({
  type: 'HIDE_ERROR_MODAL',
});

export const setNotifications = () => store.dispatch({
  type: 'DISPLAY_NOTIFICATIONS_BAR',
});

export const hideAllNotifications = () => store.dispatch({
  type: 'HIDE_NOTIFICATIONS_BAR',
});

export const hideNotification = (current) => {
  store.dispatch({
    type: 'HIDE_NOTIFICATION',
    current,
  });
};

export const setPageLoading = loading => store.dispatch({
  type: loading ? 'PAGE_LOADING' : 'PAGE_LOADED',
});

export const hideNotificationBar = () => store.dispatch({
  type: 'HIDE_DOWNLOAD_REPORT_NOTIFICATION',
});

export const setNotificationBarVisibility = cookies => (dispatch) => {
  axios.get('/report')
    .then((response) => {
      const reportDate = localStorage.getItem(Constants.NOTIFICATION_BAR.LSTORAGE_REPORT_DATE);
      if (!reportDate) {
        localStorage.setItem(Constants.NOTIFICATION_BAR.LSTORAGE_REPORT_DATE,
          response.data.latestPublishedDate);
        dispatch({
          type: 'SHOW_DOWNLOAD_REPORT_NOTIFICATION',
          date: response.data.latestPublishedDate,
        });
      } else if (parseInt(reportDate, 10) < response.data.latestPublishedDate) {
        localStorage.setItem(Constants.NOTIFICATION_BAR.LSTORAGE_REPORT_DATE,
          response.data.latestPublishedDate);
        cookies.remove(Constants.NOTIFICATION_BAR.COOKIE_NAME, { path: '/' });
        dispatch({
          type: 'SHOW_DOWNLOAD_REPORT_NOTIFICATION',
          date: response.data.latestPublishedDate,
        });
      }
    });
};

export const toggleContactUs = (open) => {
  store.dispatch({ type: 'OPEN_CONTACT_US', open });
};
