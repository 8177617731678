import React from 'react';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { isMobileView } from '../../utils';
import { toggleContactUs } from '../../redux/actions/global-actions';
import { trackEvent } from '../../analytics';

class ContactUsButton extends React.Component {
  handleClickContactUs = () => {
    toggleContactUs(true);
    trackEvent('CAT_CONTACT', {
      prefix: '',
      name: 'CLICK',
      suffix: 'on contact us button from home page',
    }, {
      prefix: '',
      name: 'OPEN',
      suffix: 'the contact us modal',
    });
  };

  render() {
    const { t } = this.props;
    return (
      <button
        type="button"
        className={classNames('contact-us-button', { 'icon-only': isMobileView() })}
        onClick={() => this.handleClickContactUs()}
      >
        <div>
          <span className="mb-icon mb-icon-help" />
          <span className="text">{t('contact.title')}</span>
        </div>
      </button>
    );
  }
}
export default withNamespaces()(ContactUsButton);
