import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { setPageAsLoadedDispatch } from '../redux/actions/global-actions';
import { fetchOutbreaks, selectedCountryData, setMapAsLoaded } from '../redux/actions/map-actions';
import { selectPathogenFilter } from '../redux/actions/pathogen-actions';
import store from '../redux/store';
import countries from '../countries';
import Seo from '../components/Seo';
import FormattedDate from '../components/UI/DataFormat/FormattedDate';
import FormattedNumber from '../components/UI/DataFormat/FormattedNumber';
import pipe, {
  prettifyUrl, setAppUrl, getDataFromUrl,
} from '../utils';
/**
 * Container to render the links to pathogens as map is not supported for rendering
 */

class StaticHome extends React.Component {
  selectedPathogen = null;

  pathogenName = 'init';

  countryCode = '';

  routePath = {};

  componentDidMount() {
    setPageAsLoadedDispatch();
    setMapAsLoaded();

    this.formatCountries();
  }

  componentDidUpdate() {
    const {
      selectedPathogen,
    } = this.props;

    this.routePath = getDataFromUrl();

    if (selectedPathogen) {
      if (this.pathogenName !== this.routePath.pathogenName) {
        this.pathogenName = this.routePath.pathogenName;
        this.selectedPathogen = selectedPathogen;
        store.dispatch(fetchOutbreaks(selectedPathogen.value));
      }
    }

    if (this.routePath.countryCode && this.countryCode !== this.routePath.countryCode) {
      this.countryCode = this.routePath.countryCode;
      store.dispatch(selectedCountryData(
        this.routePath.countryCode,
        this.props.selectedPathogen ? (this.props.selectedPathogen.value || false) : false,
      ));
    }
  }

  selectPathogen = (event) => {
    const { pathogens } = this.props;
    let found = false;
    const currentTarget = event.target;
    const urlPathogen = new URL(currentTarget.value).pathname.replace('/', '');
    const selectedPathogen = currentTarget.options[currentTarget.selectedIndex].label;

    pathogens.map((p) => {
      if (!found && p.value && p.value === selectedPathogen) {
        found = true;
        selectPathogenFilter(p);
        setAppUrl('', p);
      }

      return false;
    });

    if (!found && urlPathogen === '') {
      setAppUrl('', { label: selectedPathogen });
      selectPathogenFilter();
    }
  };

  formatCountries = () => {
    const staticCountries = {};
    const allCountriesEntries = Object.entries(countries);
    allCountriesEntries.forEach((obj) => {
      const [countryCode, countryName] = obj;
      staticCountries[countryCode] = prettifyUrl(countryName);
    });
    this.staticCountries = staticCountries;
  };

  render() {
    const {
      pathogens, data, countryData, t,
    } = this.props;
    const selectedPathogen = this.selectedPathogen ? (this.selectedPathogen.value || false) : false;

    return (
      <div className="panel">
        <div className="well well-sm">
          <i>{t('home.webgl_support')}</i>
        </div>

        <select
          onChange={this.selectPathogen}
          value={
              new URL(
                `${selectedPathogen ? `/${prettifyUrl(selectedPathogen)}` : '/'}`,
                window.location.href,
              ).href
          }
        >
          {pathogens && pathogens.map(p => (
            <option
              key={p.value}
              value={
                new URL(
                  `${p.value ? `/${prettifyUrl(p.value)}` : '/'}`,
                  window.location.href,
                ).href
              }
              disabled={p.value && !p.isNRT}
            >
              {p.label}
            </option>
          ))}
        </select>
        {
          !this.routePath.countryCode && this.props.selectedPathogen
            ? (
              <h1>
                {t('static_home.affected_countries', {
                  pathogenName: this.props.selectedPathogen.label,
                })}
              </h1>
            )
            : null
        }
        { data ? data.eventsPerCountry.map((d) => {
          if (this.routePath.countryCode) {
            return (!this.routePath.countryCode || this.routePath.countryCode === d.countryCode
              ? (
                <div key={d.countryCode}>
                  <h3>
                    <a href={
                      new URL(
                        `${
                          this.props.selectedPathogen.value
                            ? `/${prettifyUrl(this.props.selectedPathogen.value)}`
                            : '/'
                        }`,
                        window.location.href,
                      ).href}
                    >
                      <span className="mb-icon mb-icon-back" /> {
                        t('static_home.back_to_countries', {
                          pathogenName: this.props.selectedPathogen.label,
                        })}
                    </a>
                  </h3>
                  <h1>{countries[d.countryCode]}</h1>
                  <p>{t('countryPopup.details.subtitle')}: {d.numberOfEvents}</p>
                  <div>
                    {countryData && countryData.events.map(pathogenData => (
                      !this.props.selectedPathogen.value
                        || this.props.selectedPathogen.value === pathogenData.pathogenName
                        ? (
                          <div key={pathogenData.pathogenName + pathogenData.reportedCases}>
                            <h2>{pathogenData.pathogenName}</h2>

                            {['firstReportDate', 'latestReportDate', 'reportedCases', 'reportedDeaths'].map(characteristic => (
                              <div key={characteristic}>
                                <p>{t(`countryPopup.details.${characteristic}`)}
                                  {characteristic === 'firstReportDate' || characteristic === 'latestReportDate'
                                    ? <FormattedDate timestamp={pathogenData[characteristic]} format="MM/DD/YY" />
                                    : <FormattedNumber number={pathogenData[characteristic] || 0} format="comma" />
                              }
                                </p>
                              </div>
                            ))}
                          </div>
                        )
                        : null
                    ))}
                  </div>
                </div>
              ) : null
            );
          }
          return (
            <div key={d.countryCode}>
              <a href={
                      new URL(
                        `${
                          this.props.selectedPathogen.value
                            ? `/${prettifyUrl(this.props.selectedPathogen.value)}${this.staticCountries[d.countryCode]
                              ? `-in-${this.staticCountries[d.countryCode]}`
                              : ''
                            }`
                            : `${d.countryCode ? `/All-Pathogens-in-${this.staticCountries[d.countryCode]}` : '/'}`
                        }`,
                        window.location.href,
                      ).href}
              >{countries[d.countryCode]}
              </a>
            </div>
          );
        }) : null
        }

        <Seo
          title={t('seo.home.title', {
            pathogenName: this.props.selectedPathogen && this.props.selectedPathogen.pathogenName
              ? ` - ${this.props.selectedPathogen.pathogenName}`
              : null,
            countryName: this.routePath.countryCode ? ` - ${countries[this.routePath.countryCode]}` : null,
          })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.mapReducers.data,
    pathogens: state.pathogenReducers.pathogensList,
    selectedPathogen: state.pathogenReducers.selectedPathogen,
    countryData: state.mapReducers.countryData,
  };
}

export default pipe(withNamespaces(), connect(mapStateToProps))(StaticHome);
