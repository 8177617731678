import { mapDefaults } from './MapUtils';

const ResetZoomControl = class ResetZoomControl {
    map;

    container;

    constructor(map) {
      this.map = map;
    }

    resetZoom() {
      this.map.easeTo({ center: mapDefaults.center, zoom: mapDefaults.zoom });
    }

    onAdd(map) {
      this.map = map;

      this.container = document.createElement('div');
      this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

      this.button = document.createElement('button');
      this.button.className = 'mapboxgl-ctrl-icon mb-icon mb-icon-full-map';

      const self = this;
      this.button.onclick = () => { self.resetZoom(); };

      this.container.appendChild(this.button);

      return this.container;
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
};

export default ResetZoomControl;
