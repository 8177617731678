import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
// Commented as part of GB-35023 - remove comment and next line to use the download notifications
// import { setNotificationBarVisibility } from '../../redux/actions/global-actions';
import { hideAllNotifications } from '../../redux/actions/global-actions';
import NotificationBar from '../NotificationBar/NotificationBar';
import store from '../../redux/store';

class Header extends React.Component {
  componentDidMount() {
    // Commented as part of GB-35023 -
    // - remove comment and the next line to use the download notifications
    // store.dispatch(setNotificationBarVisibility(this.props.cookies));
    store.dispatch(hideAllNotifications());
  }

  render() {
    const { reportDate } = this.props;
    const dateReport = parseInt(localStorage.getItem('reportDate'), 10);
    return (
      <header>
        <NotificationBar date={dateReport || reportDate} />
      </header>
    );
  }
}

function globalStateToProps(state) {
  return {
    reportDate: state.globalReducers.reportDate,
  };
}

export default withCookies(connect(globalStateToProps, null)(Header));
