import axios from 'axios';
import FileSaver from 'file-saver';
import countries from './countries';

const pipe = (...fns) => results => [...fns].reduce((result, fn) => fn(result), results);
let urlPathogens = null;
const urlCountries = [];

export const checkFormValidity = (value, rules) => {
  const validity = {
    rule: null,
    valid: true,
  };

  if (!rules || !Object.keys(rules).length) {
    validity.valid = true;
    return validity;
  }

  for (let i = 0; i < Object.keys(rules).length; i++) {
    if (rules.required) {
      validity.rule = 'required';
      if (typeof value === 'boolean') {
        validity.valid = value;
      } else if (typeof value === 'string') {
        validity.valid = value.trim() !== '';
      } else if (typeof value === 'object') {
        validity.valid = Object.keys(value).length > 0;
      }
      if (!validity.valid) {
        break;
      }
    }
    if (rules.minLength) {
      validity.rule = 'minLength';
      validity.valid = value.trim() !== '' ? value.length >= rules.minLength : true;
      if (!validity.valid) {
        break;
      }
    }
    if (rules.maxLength) {
      validity.rule = 'maxLength';
      validity.valid = value.length <= rules.maxLength;
      if (!validity.valid) {
        break;
      }
    }
    if (rules.isEmail) {
      const pattern = /^['_A-Za-z0-9-&+]+(\.['_A-Za-z0-9-&+]+)*[.]{0,1}@([A-Za-z0-9-])+(\.[A-Za-z0-9-]+)*((\.[A-Za-z0-9]{2,})|(\.[A-Za-z0-9]{2,}\.[A-Za-z0-9]{2,}))$/;
      validity.rule = 'isEmail';
      validity.valid = pattern.test(value);
      if (!validity.valid) {
        break;
      }
    }
    if (rules.isPhone) {
      const pattern = /^(\d{8,50}$)|(\+\d{1,3}|\d{1,4}|\(\+\d{1,3}\)|\(\d{1,2}\))(([ -.]\d+){1,5}$|([ -.]\d+){1,5}([ -.](ext\.|x|extention))[ -.]\d{1,5}$)/;
      validity.rule = 'isPhone';
      validity.valid = value.trim() !== '' ? pattern.test(value) : true;
      if (!validity.valid) {
        break;
      }
    }
  }

  return validity;
};

export const validateElement = (formObj, identifier, value) => {
  const updatedFormElement = formObj[identifier];

  if (typeof value !== 'undefined') {
    updatedFormElement.value = value;
  }

  const validity = checkFormValidity(updatedFormElement.value,
    updatedFormElement.validation);

  updatedFormElement.valid = validity.valid;
  updatedFormElement.touched = true;
  updatedFormElement.errorMessage = !validity.valid
    ? updatedFormElement.validationMessages[validity.rule] : '';

  return {
    updatedFormElement,
    formObj,
  };
};

export const downloadFile = (fileURL, callback) => {
  const fileName = fileURL.substring(fileURL.lastIndexOf('/') + 1, fileURL.indexOf('?'));
  axios.get(fileURL, {
    disableCredentials: true,
    responseType: 'blob', // important
  }).then((response) => {
    FileSaver.saveAs(new Blob([response.data]), fileName);
    callback();
  }, () => {
    callback(true);
  });
};

export const isMobileView = () => window.innerWidth < 1025;
export const checkIfNotDevelopment = param => !NODE_ENVS.configuration.isDevelopment
  && param;

export const isWebGlSupported = () => {
  try {
    const canvas = document.createElement('canvas');
    return !!window.WebGLRenderingContext
            && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
  } catch (e) {
    return false;
  }
};

export const isIFrameView = () => window.self !== window.top;

// Make the url path prettier
export const prettifyUrl = (str) => {
  if (str && typeof str === 'string') {
    return str.split(' ').join('-').replace(/[/\.()]/g, '');
  }
  return null;
};

// Initialize the pathogens and the countries list - (urlPathogens, urlCountries)
// Will be called from the Home->componentDidMount component
export const setPathogens = (pathogens) => {
  urlPathogens = {};
  if (pathogens.length) {
    const isNRTPathogens = pathogens.filter(pathogen => pathogen.isNRT === true);
    for (let i = 0; i < isNRTPathogens.length; i++) {
      urlPathogens[prettifyUrl(isNRTPathogens[i].pathogenName)] = isNRTPathogens[i].pathogenName;
    }
  }
  const allCountries = Object.entries(countries);
  allCountries.forEach((obj) => {
    const [countryCode, countryName] = obj;
    urlCountries[prettifyUrl(countryName)] = countryCode;
  });
};

// Validate the url path (if the entered pathogen and/or country exists or not)
export const getDataFromUrl = () => {
  if (!urlPathogens) {
    return false;
  }

  const pathogenFromUrl = window.location.pathname;
  const pathArray = pathogenFromUrl ? pathogenFromUrl.split('-in-') : '';
  let pathogenName = '';
  let countryCode = '';

  if (pathArray && pathArray[0]) {
    pathogenName = Object.keys(urlPathogens).includes(prettifyUrl(pathArray[0])) ? urlPathogens[prettifyUrl(pathArray[0])] : '';
  }

  if (pathArray && pathArray[1]) {
    countryCode = Object.keys(urlCountries).includes(prettifyUrl(pathArray[1])) ? urlCountries[prettifyUrl(pathArray[1])] : '';
  }
  return {
    pathogenName,
    countryCode,
    pathArray,
  };
};

export const sameValues = (o, n) => {
  if ((!o && !n) || (o && n && o === n)) {
    return true;
  }

  return false;
};

// Add the selected pathogen and/or the selected country to the url
export const setAppUrl = (selectedCountry, selectedPathogen) => {
  if (!urlPathogens) {
    return false;
  }

  const { countryCode, pathArray } = getDataFromUrl();
  const pathogenName = pathArray[0].replace('/', '');

  let newPath = false;

  // what's changed? there's no point to show "/All-Pathogens" only
  const pathogenUrlName = !selectedPathogen.value && !selectedCountry
    ? ''
    : prettifyUrl(selectedPathogen.label);
  if (!sameValues(pathogenName, pathogenUrlName)) {
    if (!selectedPathogen.value || urlPathogens[pathogenUrlName]) {
      newPath = pathogenUrlName;
    }
  }

  if (!countryCode || newPath || !sameValues(selectedCountry, countryCode)) {
    const countryUrlName = prettifyUrl(countries[selectedCountry]);

    if (selectedCountry) {
      newPath = `${newPath || pathogenUrlName}-in-${countryUrlName}`;
    } else if (!newPath) {
      newPath = pathogenUrlName;
    }
  }

  const initialPath = window.location.pathname;

  // empty string is now acceptable
  if (newPath !== false && newPath !== initialPath.slice(1)) {
    window.history.pushState({}, document.title, `/${newPath}`);
  }

  return true;
};

export const getFormattedDate = (timestamp, format, utc, translate) => {
  const date = new Date(timestamp);
  const day = !utc ? date.getUTCDate() : date.getDate();
  const month = !utc ? date.getUTCMonth() : date.getMonth();
  const year = !utc ? date.getUTCFullYear() : date.getFullYear();

  if (!timestamp) {
    return '-';
  }
  switch (format) {
    case 'MMM DD, YYYY': {
      return `${translate(`formattedDate.MMM.${month}`)} ${day}, ${year}`;
    }
    case 'Month DD, YYYY': {
      return `${translate(`formattedDate.Month.${month}`)} ${day}, ${year}`;
    }
    case 'MM/DD/YY': {
      return `${month + 1}/${day}/${year}`;
    }
    case 'Month YYYY': {
      return `${translate(`formattedDate.Month.${month}`)} ${year}`;
    }
    default:
      return '-';
  }
};


export default pipe;
