const initialState = {
  loading: true,
  error: false,
  pageLoading: false,
  showNotification: true,
  googleAnalyticsTrackOptIn: true,
  reportDate: false,
  openedAttribution: false,
  showContactUs: false,
};

export default function globalReducers(state = initialState, action) {
  switch (action.type) {
    case 'INIT_APP_COMPLETED':
      return Object.assign({}, state, {
        loading: false,
      });
    case 'PAGE_LOADING':
      return Object.assign({}, state, {
        pageLoading: true,
      });
    case 'PAGE_LOADED':
      return Object.assign({}, state, {
        pageLoading: false,
      });
    case 'DISPLAY_ERROR_MODAL':
      return Object.assign({}, state, {
        error: true,
      });
    case 'HIDE_ERROR_MODAL':
      return Object.assign({}, state, {
        error: false,
      });
    case 'SHOW_DOWNLOAD_REPORT_NOTIFICATION':
      return {
        ...state,
        showNotification: true,
        reportDate: action.date,
      };
    case 'HIDE_DOWNLOAD_REPORT_NOTIFICATION':
      return Object.assign({}, state, {
        showNotification: false,
      });
    case 'GOOGLE_ANALYTICS_OPT_IN_ON':
      return {
        ...state,
        googleAnalyticsTrackOptIn: true,
      };
    case 'GOOGLE_ANALYTICS_OPT_IN_OFF':
      return {
        ...state,
        googleAnalyticsTrackOptIn: false,
      };
    case 'OPEN_ATTRIBUTION':
      return {
        ...state,
        openedAttribution: true,
      };
    case 'CLOSE_ATTRIBUTION':
      return {
        ...state,
        openedAttribution: false,
      };
    case 'OPEN_CONTACT_US':
      return {
        ...state,
        showContactUs: action.open,
      };
    default:
      return state;
  }
}
