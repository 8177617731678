import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import {
  Home, Privacy, StaticHome, Terms,
} from './containers';
import { isWebGlSupported } from './utils';

class Routes extends React.Component {
  updateScroll = () => { this.scrollBarRef.updateScroll(); };

  render() {
    return (
      <Switch>
        <Route
          path="/privacy"
          render={() => <Privacy onUpdateSize={this.updateScroll} />}
        />
        <Route
          path="/terms"
          render={() => <Terms onUpdateSize={this.updateScroll} />}
        />
        { isWebGlSupported()
          ? (
            <Route
              path="*"
              render={() => (<Home cookies={this.props.cookies} key={window.location.pathname} />)}
            />
          )
          : (
            <Route
              path="*"
              render={() => (<StaticHome />)}
            />
          )
        }
      </Switch>
    );
  }
}

export default withCookies(Routes);
