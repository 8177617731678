import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PopupDetailedView from './Popup/PopupDetailedView';
import ContentLoader from '../ContentLoader';
import countryCoordinates from '../../countryCoordinates';

const PopupContent = class PopupContent extends React.Component {
  popupPosition = false;

  componentDidUpdate() {
    setTimeout(() => {
      this.setPopupPosition();
    });
  }

  getPopupContent() {
    const { countryData, pathogensList } = this.props;
    const closeClassname = 'close-popup detailed-view';
    return (
      <PopupDetailedView
        onCloseCallback={this.closePopup}
        closeClassname={closeClassname}
        countryData={countryData}
        pathogensList={pathogensList}
      />
    );
  }

  getLoader() {
    const { countryDataLoading, countryData } = this.props;
    return (
      <div className="popup-content no-scrollbar">
        <ContentLoader contentLoading={countryDataLoading || !countryData} />
      </div>
    );
  }

  closePopup = () => {
    if (this.props.onCloseCallback) {
      this.props.onCloseCallback();
    }
  };

  setPopupPosition = () => {
    const {
      map,
      mapContainer,
      mapPopupContainer,
      event,
      countryData,
    } = this.props;
    let position = null;

    if (map && event) {
      if (event.type === 'click' || event.type === 'manual') {
        this.popupPosition = event.lngLat;
        position = map.project(event.lngLat);
      } else {
        // workaround: there are situations with back/forward where popupPosition isn't set
        // TBD: send position via props rather than determining it within component
        if (!this.popupPosition && countryData.countryCode) {
          this.popupPosition = {
            lat: countryCoordinates[countryData.countryCode][0],
            lng: countryCoordinates[countryData.countryCode][1],
          };
        }
        position = map.project(this.popupPosition);
      }

      if (event.type === 'manual') {
        map.flyTo({
          center: this.popupPosition,
        });
      }

      const arrowContainer = document.getElementsByClassName('map-panel-arrow-container');
      const arrow = document.getElementsByClassName('map-panel-arrow');

      if (position && mapPopupContainer && arrowContainer[0] && arrow[0]) {
        const resultPosition = this.calculatePopupPosition(
          position,
          mapPopupContainer.offsetWidth,
          mapPopupContainer.offsetHeight,
          arrowContainer[0].clientHeight,
          mapContainer.clientWidth,
          mapContainer.clientHeight,
        );
        const resultDirection = this.calculatePopupDirection(position);
        mapPopupContainer.style.top = resultPosition.popupCssObject.top;
        mapPopupContainer.style.left = resultPosition.popupCssObject.left;

        arrowContainer[0].style.display = resultPosition.arrowCssObject.display;
        arrowContainer[0].style.top = resultPosition.arrowCssObject.top;
        arrowContainer[0].style.left = resultDirection.arrowContainerCssObject.left;
        arrowContainer[0].style.marginLeft = resultDirection.arrowContainerCssObject['margin-left'];
        arrow[0].style.marginLeft = resultDirection.arrowCssObject['margin-left'];
      }
    }
  };

  calculatePopupDirection = (originalEvent) => {
    const { mapContainer, mapPopupContainer } = this.props;
    const arrowContainerCssObject = {};
    const arrowCssObject = {};

    if (originalEvent.x <= mapContainer.clientWidth - mapPopupContainer.offsetWidth - 15) {
      // set arrow in left side
      arrowContainerCssObject.left = '-20px';
      arrowCssObject['margin-left'] = '12px';
    } else {
      // set arrow in right side
      arrowContainerCssObject.left = `${mapPopupContainer.offsetWidth}px`;
      arrowCssObject['margin-left'] = '-9px';
    }

    return {
      arrowContainerCssObject,
      arrowCssObject,
    };
  };

  calculatePopupPosition = (
    position,
    popupWidth,
    popupHeight,
    arrowHeight,
    mapWidth,
    mapHeight,
  ) => {
    const popupCssObject = {};
    const arrowCssObject = {};
    const mapMargins = 20;
    const x = position.x + 15;
    const y = position.y - 15;

    // x axis position
    if (x < mapMargins * 2) {
      popupCssObject.left = `${mapMargins * 2}px`;
    } else if (x > mapWidth) {
      popupCssObject.left = `${mapWidth - popupWidth - mapMargins}px`;
    } else if (x > mapWidth - popupWidth) {
      popupCssObject.left = `${x - popupWidth - mapMargins - 8}px`;
    } else {
      // set arrow in right side
      popupCssObject.left = `${x}px`;
    }

    // y axis position
    if (popupHeight + mapMargins * 2 < mapHeight) {
      // map container height is greater than popup height plus 40px padding (top, bottom 20px each)
      if (y < mapMargins) {
        arrowCssObject.display = 'none';
        popupCssObject.top = `${mapMargins}px`;
      } else if (y >= mapMargins && y < popupHeight / 2 + mapMargins * 2) {
        arrowCssObject.display = 'block';
        arrowCssObject.top = `${y - mapMargins}px`;
        popupCssObject.top = `${mapMargins}px`;
      } else if (y >= popupHeight / 2 + mapMargins * 2 && y < mapHeight - popupHeight / 2) {
        arrowCssObject.display = 'block';
        arrowCssObject.top = `${popupHeight / 2 - 15}px`;
        popupCssObject.top = `${y + 15 - popupHeight / 2}px`;
      } else if (y >= mapHeight - popupHeight / 2 && y < mapHeight - mapMargins * 2) {
        arrowCssObject.display = 'block';
        arrowCssObject.top = `${popupHeight - (mapHeight - y - 17)}px`;
        popupCssObject.top = `${mapHeight - popupHeight - 17}px`;
      } else {
        arrowCssObject.display = 'none';
        popupCssObject.top = `${mapHeight - popupHeight - mapMargins}px`;
      }
    } else {
      // map container height is less than popup height plus 40px padding (top, bottom 20px each)
      popupCssObject.top = `${mapMargins}px`;
      if (y >= mapMargins && y < mapHeight - mapMargins * 2) {
        arrowCssObject.display = 'block';
        arrowCssObject.top = `${y - mapMargins}px`;
      } else {
        arrowCssObject.display = 'none';
      }
    }

    return {
      popupCssObject,
      arrowCssObject,
    };
  };

  render() {
    const {
      countryDataLoading,
      countryData,
      popupOpened,
      event,
      popupInModal,
    } = this.props;

    return popupOpened && event ? (
      <div
        className={classNames('mapboxgl-popup-content', { 'no-padding': popupInModal })}
      >
        <div
          className="map-panel-arrow-container"
          style={{ display: (popupInModal) ? 'none' : 'block' }}
        >
          <div className="map-panel-arrow" />
        </div>
        <div id="popup-content">
          { (countryDataLoading || !countryData) ? this.getLoader() : this.getPopupContent() }
        </div>
      </div>
    ) : null;
  }
};

function mapStateToProps(state) {
  return {
    countryData: state.mapReducers.countryData,
    countryDataLoading: state.mapReducers.countryDataLoading,
    pathogensList: state.pathogenReducers.pathogensList,
    selectedPathogen: state.pathogenReducers.selectedPathogen,
    popupOpened: state.mapReducers.popupOpened,
    event: state.mapReducers.event,
  };
}
export default connect(mapStateToProps)(PopupContent);
