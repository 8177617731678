import {
  USER_SYSTEM_NOTIFICATION,
  USER_SYSTEM_NOTIFICATION_CLEAR,
} from '../../constants/action-types';

export const notifySuccess = (message, duration) => (dispatch) => {
  dispatch({
    type: USER_SYSTEM_NOTIFICATION,
    payload: {
      type: 'completed', message, duration,
    },
  });
};

export const notifyWarning = (message, labelBtn, callbackBtn, duration) => (dispatch) => {
  dispatch({
    type: USER_SYSTEM_NOTIFICATION,
    payload: {
      type: 'warning', message, labelBtn, callbackBtn, duration,
    },
  });
};

export const notifyFailure = (message, labelBtn, callbackBtn, duration) => (dispatch) => {
  dispatch({
    type: USER_SYSTEM_NOTIFICATION,
    payload: {
      type: 'error', message, labelBtn, callbackBtn, duration,
    },
  });
};

export const clearNotification = i => (dispatch) => {
  dispatch({ type: USER_SYSTEM_NOTIFICATION_CLEAR, index: i });
};
