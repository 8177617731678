import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ContentLoader from '../ContentLoader';
import pipe from '../../utils';

const PathogenProfileDetails = ({ t, pathogenDetails, pathogenDetailsLoading }) => {
  const displayDetails = pathogenDetails && Object.keys(pathogenDetails).map((key, index) => {
    if (key === 'pathogenName') {
      return <div className="semibold-font-weight font-size-small" key={key}>{pathogenDetails[key]}</div>;
    }

    let elementStyle = {};
    if (index === Object.keys(pathogenDetails).length - 1) {
      elementStyle = { paddingBottom: '20px' };
    }
    return (
      <div key={key} className="font-size-small padding-top" style={elementStyle}>
        <div className="semibold-font-weight">{t(`pathogenProfile.${key}`)}</div>
        <div>{key === 'primaryTransmissionType' ? pathogenDetails[key].join(', ') : pathogenDetails[key] }</div>
      </div>
    );
  });

  const loader = <ContentLoader contentLoading={pathogenDetailsLoading} />;
  const detailsClasses = classNames('pathogen-profile-details padding-left padding-right', {
    hidden: !pathogenDetails && !pathogenDetailsLoading,
  });

  return (
    <PerfectScrollbar>
      <div className={detailsClasses}>
        {
          pathogenDetailsLoading
            ? loader
            : displayDetails
        }
      </div>
    </PerfectScrollbar>
  );
};


function stateToProps(state) {
  return {
    pathogenDetailsLoading: state.pathogenReducers.pathogenDetailsLoading,
  };
}

export default pipe(withNamespaces(), connect(stateToProps))(PathogenProfileDetails);
