import React from 'react';

const ContentLoader = ({ contentLoading }) => (
  contentLoading && (
  <div className="wrapper-loader">
    <div className="content-loader">
      <span className="mb-loader mb-icon-spinner" />
    </div>
  </div>
  )
);
export default ContentLoader;
