import ReactGA from 'react-ga';
import * as Constants from './constants/google-analytics';
import { checkIfNotDevelopment, isIFrameView } from './utils';

const init = () => {
  if (checkIfNotDevelopment(NODE_ENVS.configuration.googleAnalyticsId)) {
    ReactGA.initialize(`${NODE_ENVS.configuration.googleAnalyticsId}`);
  }
};

export const pageView = (name) => {
  if (checkIfNotDevelopment(NODE_ENVS.configuration.googleAnalyticsId)) {
    ReactGA.pageview(name === '/' ? '/home' : name);
  }
};

export const modalView = (name, details = 'MODAL', pathogen = '') => {
  if (checkIfNotDevelopment(NODE_ENVS.configuration.googleAnalyticsId)) {
    const pathogens = pathogen === '' ? pathogen : `-${pathogen}`;
    ReactGA.modalview(`${name}-${Constants[details]}${pathogens}`);
  }
};

export const trackEvent = (category, action, label, value = 0, interaction = false) => {
  if (checkIfNotDevelopment(NODE_ENVS.configuration.googleAnalyticsId)) {
    ReactGA.event({
      category: Constants[category],
      action: `${isIFrameView() ? 'IFrame: ' : ''}${action.prefix} ${Constants[action.name]} ${action.suffix}`,
      label: `${label.prefix} ${Constants[label.name]} ${label.suffix}`,
      value,
      nonInteraction: interaction,
    });
  }
};

const gaMethods = {
  init,
  pageView,
  trackEvent,
  modalView,
};

export default gaMethods;
