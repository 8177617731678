import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';
import ModalDialog from '../ModalDialog/ModalDialog';
import { trackEvent, pageView } from '../../analytics';
import * as Constants from '../../constants';
import PopupContent from './PopupContent';
import { toggleCountryModal } from '../../redux/actions/map-actions';

class PopupModalContent extends ReactQueryParams {
  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  closeModal = () => {
    trackEvent('CAT_MAP', {
      prefix: '',
      name: 'CLOSE',
      suffix: `${this.props.countryData.countryCode} ${this.props.selectedPathogen.label}`,
    }, {
      prefix: '',
      name: 'CLOSE',
      suffix: 'the modal from map',
    });
    pageView('/');
    toggleCountryModal(false);
  };

  onUpdate = () => {};

  render() {
    return (
      <ModalDialog
        isOpen={this.props.countryModalOpen}
        modalClass="no-padding"
        type={Constants.MODAL_TYPE.MAP}
      >
        <PopupContent
          onCloseCallback={this.closeModal}
          onUpdateCallback={this.onUpdate}
          popupInModal={this.props.countryModalOpen}
        />
      </ModalDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    countryData: state.mapReducers.countryData,
    selectedPathogen: state.pathogenReducers.selectedPathogen,
    countryModalOpen: state.mapReducers.countryModalOpened,
  };
}

export default connect(mapStateToProps)(PopupModalContent);
