import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = (props) => {
  const { title, description, keywords } = props;

  return (
    <Helmet>
      { title ? <title>{title}</title> : null }
      { description ? <meta name="description" content={description} /> : null }
      { keywords ? <meta name="keywords" content={keywords} /> : null }
    </Helmet>
  );
};

export default Seo;
