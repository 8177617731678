import axios from 'axios';
import store from '../store';
import { setPageLoading, setPageError, setPageAsLoaded } from './global-actions';

export const setMapData = data => ({
  type: 'SET_MAP_DATA',
  data,
});

export const fetchOutbreaks = pathogenName => (dispatch) => {
  setPageLoading(true);
  return axios.get(`/events${(pathogenName ? `?pathogenName=${encodeURIComponent(pathogenName)}` : '')}`)
    .then((response) => {
      dispatch(setMapData(response.data));
      setPageLoading(false);
    })
    .catch(() => {
      dispatch(setPageAsLoaded());
      dispatch(setPageError());
      setPageLoading(false);
    });
};

export const setMapAsLoaded = (isFalse) => {
  store.dispatch({
    type: isFalse ? 'MAP_NOT_LOADED' : 'MAP_LOADED',
  });
};

export const setMapAsLoadedAsync = isFalse => (dispatch) => {
  dispatch({ type: isFalse ? 'MAP_NOT_LOADED' : 'MAP_LOADED' });
};


export const selectCountry = cCode => store.dispatch({
  type: 'SELECT_COUNTRY',
  cCode,
});

export const selectedCountryData = (cCode, pathogen) => (dispatch) => {
  store.dispatch({
    type: 'DESELECT_COUNTRY',
  });
  const noData = {
    countryCode: cCode,
    events: [],
    numberOfEvents: 0,
    numberOfPathogens: 0,
  };
  dispatch({ type: 'COUNTRY_DATA_LOADING' });
  let param = cCode;
  if (pathogen) {
    param += `?pathogenName=${encodeURIComponent(pathogen)}`;
  }
  axios.get(`/events/${param}`)
    .then((response) => {
      dispatch({ type: 'SELECTED_COUNTRY_DATA', data: response.data });
      dispatch({ type: 'COUNTRY_DATA_LOADED' });
    })
    .catch((error) => {
      const statusCode = error.response.status;

      if (statusCode === 404) {
        dispatch({ type: 'SELECTED_COUNTRY_DATA', data: noData });
      } else {
        dispatch(setPageError());
      }
      dispatch({ type: 'COUNTRY_DATA_LOADED' });
    });
};

export const deselectCountry = () => store.dispatch({
  type: 'DESELECT_COUNTRY',
});

export const openPanel = panel => (dispatch) => {
  dispatch({
    type: 'OPEN_PANEL',
    panel,
  });
};

export const closePanel = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_PANEL',
  });
};

export const openPopup = open => store.dispatch({
  type: 'OPEN_POPUP',
  open,
});

export const setPopupParams = event => store.dispatch({
  type: 'SET_POPUP_POSITION',
  event,
});

export const toggleCountryModal = open => store.dispatch({
  type: 'OPEN_COUNTRY_MODAL',
  open,
});
