import React from 'react';
import { connect } from 'react-redux';

const PageLoader = ({ pageLoading }) => (
  pageLoading && (
    <div className="page-loader">
      <span className="mb-loader mb-icon mb-icon-spinner" />
    </div>
  )
);


function PageLoaderStateToProps(state) {
  return {
    pageLoading: state.globalReducers.pageLoading,
  };
}
export default connect(PageLoaderStateToProps)(PageLoader);
