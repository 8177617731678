import React from 'react';

export default ({
  children, handleNavigation, t, title, currentOption,
}) => (children ? (
  <div className={`side-panel-page ${currentOption.key ? currentOption.key : ''}`}>
    <div className="panel-body">
      <div className="side-panel-title">{t(title)}</div>

      <div className="close-page pull-right" role="presentation" onClick={() => handleNavigation()}>
        <span className="mb-icon mb-icon-dismiss" />
      </div>
    </div>
    <div className="side-panel-content">{children}</div>
  </div>
) : null);
