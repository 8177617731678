import ReactDOM from 'react-dom';

const MapControl = class MapControl {
    map;

    container = document.createElement('div');

    constructor(element, className = false) {
      if (className) {
        this.container.className = className;
      }

      ReactDOM.render(element, this.container);
    }

    onAdd(map) {
      this.map = map;

      return this.container;
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
};

export default MapControl;
