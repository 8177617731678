import React from 'react';
import { withNamespaces } from 'react-i18next';
import ReactQueryParams from 'react-query-params';
import { connect } from 'react-redux';
import { pageView } from '../analytics';
import pipe, {
  isMobileView, getDataFromUrl,
} from '../utils';
import Seo from '../components/Seo';
import Map from './Map';
import SidePanel from './SidePanel';
import NAVIGATION_OPTIONS from './SidePanelItems';
import {
  resetPathogen, selectPathogenFilter,
} from '../redux/actions/pathogen-actions';
import InfoCircleContainer from './InfoCircleContainer';
import store from '../redux/store';
import {
  closePanel, selectCountry,
} from '../redux/actions/map-actions';
import ContactUsButton from '../components/ContactUs/ContactUsButton';


class Home extends ReactQueryParams {
  componentDidMount() {
    document.addEventListener('click', this.closeSidePanel, false);
    pageView(`/${window.location.pathname}`);
  }

  componentDidUpdate() {
    const urlParams = getDataFromUrl();
    if (urlParams.pathogenName) {
      selectPathogenFilter({ value: urlParams.pathogenName, label: urlParams.pathogenName });
    }
    if (urlParams.countryCode) {
      selectCountry(urlParams.countryCode);
    }
    pageView(`/${window.location.pathname}`);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeSidePanel);
    resetPathogen();
  }

  closeSidePanel = (event) => {
    // in desktop view close sidepanel when clicking outside panel
    if (!event.target.closest('.side-panel')
      && document.getElementsByClassName('side-panel-page').length > 0
      && !event.target.classList.contains('popup-link')
      && !event.target.classList.contains('react-select__option') && !isMobileView()) {
      store.dispatch(closePanel());
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div className="full-height">
        <Seo
          title={t('seo.home.title')}
          description={t('seo.home.description')}
          keywords={t('seo.genericKeywords')}
        />
        <React.Fragment>
          <Map translate={t} />
          <SidePanel options={NAVIGATION_OPTIONS(t)} />
          <InfoCircleContainer />
          <ContactUsButton />
        </React.Fragment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showNotification: state.globalReducers.showNotification,
    data: state.mapReducers.data,
    pathogens: state.pathogenReducers.pathogensList,
    countryData: state.mapReducers.countryData,
  };
}

export default pipe(withNamespaces(), connect(mapStateToProps))(Home);
