const ZoomControl = class ZoomControl {
    map;

    container = document.createElement('div');

    zoomInButton = document.createElement('button');

    zoomOutButton = document.createElement('button');

    onAdd(map) {
      this.map = map;

      this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group zoom-control-buttons';
      this.zoomInButton.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in';
      this.zoomOutButton.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out disabled';

      const self = this;
      this.zoomInButton.onclick = () => {
        self.zoomIn();
      };
      this.zoomOutButton.onclick = () => {
        self.zoomOut();
      };

      this.container.appendChild(this.zoomInButton);
      this.container.appendChild(this.zoomOutButton);

      this.minPossibleZoom = this.map.getZoom();

      return this.container;
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }

    zoomIn() {
      const zoomLevel = Math.min(this.map.getZoom() + 1, this.map.getMaxZoom());
      this.map.easeTo({
        center: this.map.coordinates,
        zoom: zoomLevel,
      });
    }

    zoomOut() {
      const expectedZoom = this.map.getZoom() - 1;
      this.map.easeTo({
        center: this.map.coordinates,
        zoom: expectedZoom,
      });

      if (this.map.getZoom() < this.minPossibleZoom) {
        this.minPossibleZoom = this.map.getZoom();
      }
    }

    enableZoomInButton() {
      this.zoomInButton.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in';
    }

    disableZoomInButton() {
      this.zoomInButton.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in disabled';
    }

    enableZoomOutButton() {
      this.zoomOutButton.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out';
    }

    disableZoomOutButton() {
      this.zoomOutButton.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out disabled';
    }

    handleZoomStart = () => {
      this.startZoom = this.map.getZoom();
    }

    handleZoomEnd = () => {
      if (this.map.getZoom() === this.startZoom && this.startZoom !== this.map.getMaxZoom()) {
        this.minPossibleZoom = this.map.getZoom();
      }

      this.handleZoomButtons();
    }

    handleZoomButtons = () => {
      if (Math.floor(1000 * this.map.getZoom()) === Math.floor(1000 * this.minPossibleZoom)) {
        this.disableZoomOutButton();
      } else {
        this.enableZoomOutButton();
      }

      if (this.map.getZoom() >= this.map.getMaxZoom()) {
        this.disableZoomInButton();
      } else {
        this.enableZoomInButton();
      }
    }
};

export default ZoomControl;
