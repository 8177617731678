import React from 'react';
import MapFilters from './Map/MapFilters';
import PathogenProfiles from '../components/PathogenProfile/PathogenProfiles';

const NAVIGATION_OPTIONS = () => [
  {
    key: 'mapFilters',
    title: 'home.sidePanelTitles.mapFilters',
    icon: 'mb-icon mb-icon-map-filter',
    component: <MapFilters />,
  },
  {
    key: 'pathogenProfiles',
    title: 'home.sidePanelTitles.pathogenProfiles',
    icon: 'mb-icon mb-icon-pathogen-profile',
    component: <PathogenProfiles />,
  },
];

export default NAVIGATION_OPTIONS;
