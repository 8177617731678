import React from 'react';
import MapControl from './MapControl';
import HeatmapLegend from './Controls/HeatmapLegend';
import LatestDataLegend from './Controls/LatestDataLegend';

const MapLegends = (active) => {
  const controls = [];

  if (active) {
    controls.push(new MapControl(<LatestDataLegend />, 'last'));
  }

  controls.push(new MapControl(<HeatmapLegend />, controls.length ? false : 'last'));

  return controls;
};

export default MapLegends;
