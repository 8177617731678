import React from 'react';

const FormattedNumber = class FormattedNumber extends React.Component {
  getFormattedNumber() {
    const { number, format } = this.props;
    switch (format) {
      case 'comma': {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      default:
        return number;
    }
  }

  render() {
    const { classNames } = this.props;
    return (
      <span className={classNames}> { this.getFormattedNumber()} </span>
    );
  }
};

export default FormattedNumber;
