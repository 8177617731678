import React from 'react';
import classNames from 'classnames';
import store from '../../redux/store';
import { clearNotification } from '../../redux/actions/notification-actions';

class Notification extends React.Component {
  componentDidMount() {
    const duration = parseInt(this.props.duration, 10) || '';

    // fallback in case the animation event doesn't fire
    const timeoutDuration = (duration * 1000) + 500;
    const unMountDuration = 500;
    this.notifyRef.classList.toggle('show');
    this.timeout = setTimeout(() => {
      this.notifyRef.classList.toggle('show');
      setTimeout(() => {
        store.dispatch(clearNotification(this.props.index));
      }, unMountDuration);
    }, timeoutDuration);
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  handleClick = (e) => {
    e.preventDefault();
    this.props.onClicked();
    store.dispatch(clearNotification(this.props.index));
  };

  assignNotifyRef = target => this.notifyRef = target;

  render() {
    const {
      type, message, labelBtn,
    } = this.props;
    const titleClass = classNames('notification-title-text', {
      'no-button': !labelBtn,
    });
    return (
      <div ref={this.assignNotifyRef} className={`notification-body-full-width notification-${type}`}>
        <div className="notification-body-container">
          <div className="notification-title">
            <span className={`mb-icon mb-icon-${type}`} />
            <span className={titleClass}>{message}</span>
            <div
              role="presentation"
              id="button-notification"
              onClick={this.handleClick}
            >
              {labelBtn}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Notification.defaultProps = {
  onClicked: () => ({}),
  duration: 4,
};

export default Notification;
