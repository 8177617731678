import axios from 'axios';
import { notifySuccess, notifyFailure } from './notification-actions';

export const submitContactForm = (formData, resetForm, tryAgain) => (dispatch) => {
  axios.post('/contact', formData)
    .then(() => {
      resetForm(false);
      dispatch(notifySuccess('contact.formFeedback.messageSent'), 30);
    })
    .catch(() => {
      resetForm(true);
      dispatch(notifyFailure('contact.formFeedback.messageNotSent', 'contact.tryAgain', tryAgain(formData, resetForm, tryAgain)), 30);
    });
};

export const submitReportForm = callback => (dispatch) => {
  axios.post('/report')
    .then((response) => {
      callback(response.data);
    })
    .catch(() => {
      dispatch(notifyFailure('contact.formFeedback.downloadFailed'), 30);
      callback();
    });
};
