import React from 'react';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';
import { trackEvent } from '../../analytics';
import mapboxLogoWhite from '../../assets/images/mapbox-logo-white.svg';
import mapboxLogoBlack from '../../assets/images/mapbox-logo-black.svg';
import SocialShare from './SocialShare';

class Footer extends React.Component {
  state = {
    showTooltip: false,
  };

  currentYear = new Date().getFullYear();

  attributionList = (t, c, smallScreen) => {
    const listClass = classNames('list-inline no-bottom-margin', c);
    const logo = smallScreen ? mapboxLogoBlack : mapboxLogoWhite;
    return (
      <ul className={listClass}>
        <li className="hidden-xs hidden-sm">|</li>
        <li>
          <a href="http://mapbox.com/about/maps" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="mapbox" width={53} height={12} />
          </a>
        </li>
        <li>
          <div>
            <span>©&nbsp;</span>
            <a
              href="http://www.openstreetmap.org/copyright"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('mapbox.attribution.openStreetMap')}
            </a>
          </div>
        </li>
        <li>
          <a href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noopener noreferrer">
            {t('mapbox.attribution.improve')}
          </a>
        </li>
      </ul>
    );
  };

  trackOpenLink = (e) => {
    e.preventDefault();
    trackEvent(
      'CAT_FOOTER',
      {
        prefix: '',
        name: 'CLICK',
        suffix: 'on link metabiota from attribution',
      },
      {
        prefix: '',
        name: 'OPEN',
        suffix: 'the metabiota website',
      },
    );
    window.open(e.target.href);
  };

  handleClick = (e) => {
    this.setState({
      showTooltip: this.node.contains(e.target),
    });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  render() {
    const { t } = this.props;
    const infoIconClasses = classNames('mb-icon', {
      'mb-icon-info-hover-mobile': this.state.showTooltip,
      'mb-icon-info-icon': !this.state.showTooltip,
    });
    const mobileAttrClasses = classNames('left-side visible-xs visible-sm', {
      opened: this.state.showTooltip,
      closed: !this.state.showTooltip,
    });
    return (
      <footer>
        <div className="hidden-xs hidden-sm">
          <ul className="list-inline map-attribution">
            <li>
              © {this.currentYear}{' '}
              <a
                onClick={this.trackOpenLink}
                href="http://ginkgobiosecurity.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('mapbox.attribution.company')}
              </a>{' '}
              {t('mapbox.attribution.rights')}
            </li>
            <li>
              <a
                href="/terms"
                className="btn-link-default"
                type="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('mapbox.attribution.terms')}
              </a>
            </li>
            <li>
              <a
                href="https://www.ginkgobiosecurity.com/privacy-policy/"
                className="btn-link-default"
                type="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('mapbox.attribution.privacy')}
              </a>
            </li>
            <li>
              <a
                href="https://www.ginkgobioworks.com/cookie-declaration/"
                className="btn-link-default"
                type="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('mapbox.attribution.cookies')}
              </a>
            </li>
            <li>{this.attributionList(t)}</li>
            <li className="hidden-xs hidden-sm">|</li>
          </ul>
        </div>
        <div className={mobileAttrClasses}>
          <div ref={node => (this.node = node)}>
            <span className={infoIconClasses} />
            {this.state.showTooltip && (
              <div className="list-attribution">
                <ul className="list-inline map-attribution">
                  <li>
                    © {this.currentYear}{' '}
                    <a
                      onClick={this.trackOpenLink}
                      href="http://ginkgobiosecurity.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('mapbox.attribution.company')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/terms"
                      className="btn-link-default"
                      type="button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('mapbox.attribution.terms')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.ginkgobiosecurity.com/privacy-policy/"
                      className="btn-link-default"
                      type="button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('mapbox.attribution.privacy')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.ginkgobioworks.com/cookie-declaration/"
                      className="btn-link-default"
                      type="button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('mapbox.attribution.cookies')}
                    </a>
                  </li>
                </ul>
                {this.attributionList(t, 'map-attribution', true)}
              </div>
            )}
          </div>
        </div>
        <SocialShare {...this.props} />
      </footer>
    );
  }
}

export default withNamespaces()(Footer);
