import React from 'react';

const AsideBtn = ({
  options, handleNavigation, currentOption, t,
}) => (
  <React.Fragment>
    {options.map(({ icon, key }) => (
      <div key={key} className={key}>
        <i
          role="presentation"
          className={icon}
          disabled={currentOption === key}
          onClick={() => handleNavigation(key)}
        >
          <div className="side-button-name">{ t(`home.sidePanelTitles.${key}`) }</div>
        </i>
      </div>
    ))}
  </React.Fragment>
);

export default AsideBtn;
