import React from 'react';
import Tippy from '@tippy.js/react';
// eslint-disable-next-line
import tippy from 'tippy.js';

export const Tooltip = props => <Tippy {...props} />;

Tooltip.showCallback = (instance) => {
  tippy.hideAll({ duration: 0, exclude: instance });
};
Tooltip.defaultProps = {
  content: '',
  animation: 'fade',
  arrow: true,
  onShow: Tooltip.showCallback,
};

export const TooltipDesktop = props => <div className="hidden-xs hidden-sm"><Tooltip {...props} /></div>;
TooltipDesktop.defaultProps = {
  animation: 'fade',
  arrow: true,
  interactive: true,
};

export const TooltipMobile = props => <div className="visible-xs visible-sm"><Tooltip {...props} /></div>;
TooltipMobile.defaultProps = {
  animation: 'fade',
  arrow: true,
  trigger: 'click',
  interactive: true,
  sticky: true,
};

export const TooltipSmUp = props => <div className="hidden-xs"><Tooltip {...props} /></div>;
TooltipSmUp.defaultProps = {
  animation: 'fade',
  arrow: true,
  interactive: true,
};
