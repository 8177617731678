import { getDataFromUrl } from '../../utils';

const initialState = {
  selectedPathogenProfile: false,
  pathogensList: [],
  selectedPathogen: null,
  isClearable: false,
  pathogenDetailsLoading: false,
};

export default function pathogenReducers(state = initialState, action) {
  switch (action.type) {
    case 'PATHOGEN_PROFILE_SELECTED_PATHOGEN_PROFILE_REQUEST': {
      return Object.assign({}, state, {});
    }
    case 'PATHOGEN_PROFILE_SELECTED_PATHOGEN_PROFILE': {
      return Object.assign({}, state, {
        selectedPathogenProfile: action.pathogenDetails,
      });
    }
    case 'RESET_PATHOGEN': {
      return {
        ...state,
        selectedPathogen: state.pathogensList[0],
        isClearable: false,
      };
    }
    case 'SELECT_PATHOGEN_FILTER': {
      return {
        ...state,
        selectedPathogen: action.selected ? action.selected : state.pathogensList[0],
        isClearable: action.isClearable,
      };
    }
    case 'SET_PATHOGENS_DATA': {
      const urlParams = getDataFromUrl();
      // try to select the default from url if any
      const selectedPathogen = action.data.filter(
        pathogen => pathogen.label === urlParams.pathogenName,
      ).shift();

      return {
        ...state,
        pathogensList: action.data,
        selectedPathogen: selectedPathogen && selectedPathogen.isNRT
          ? selectedPathogen : action.data[0],
        // only valid pathogens are clearable
        isClearable: !!(selectedPathogen && selectedPathogen.isNRT),
      };
    }
    case 'PATHOGEN_DETAILS_LOADING':
      return Object.assign({}, state, {
        pathogenDetailsLoading: true,
      });
    case 'PATHOGEN_DETAILS_LOADED':
      return Object.assign({}, state, {
        pathogenDetailsLoading: false,
      });
    default:
      return state;
  }
}
