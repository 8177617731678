import { createBrowserHistory } from 'history';
import { pageView } from './analytics';

const browserHistory = createBrowserHistory();

browserHistory.listen((location) => {
  pageView(location.pathname);
  window.scrollTo(0, 0);
});

export default browserHistory;
