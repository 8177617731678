import React from 'react';
import { connect, Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import ReactQueryParams from 'react-query-params';
import { modalView, trackEvent } from '../analytics';
import {
  fetchOutbreaks,
  setMapAsLoaded,
  selectedCountryData,
  closePanel, selectCountry, deselectCountry,
  openPopup, setPopupParams,
  toggleCountryModal,
} from '../redux/actions/map-actions';
import { setPageAsLoadedDispatch, setPageLoading } from '../redux/actions/global-actions';
import { fetchPathogens } from '../redux/actions/pathogen-actions';
import store from '../redux/store';
import ZoomControl from '../components/Map/ZoomControl';
import Footer from '../components/Footer/Footer';
import PulsatingDot from '../components/Map/PulsatingDot';
import countryCoordinates from '../countryCoordinates';
import { heatMapColors, mapDefaults, getMapTooltipPosition } from '../components/Map/MapUtils';
import MapLegends from '../components/Map/MapLegends';
import ResetZoomControl from '../components/Map/ResetZoomControl';
import PopupContent from '../components/Map/PopupContent';
import PopupModalContent from '../components/Map/PopupModalContent';
import countries from '../countries';
import {
  getDataFromUrl,
  isIFrameView,
  isMobileView,
  setAppUrl,
} from '../utils';
import Seo from '../components/Seo';

mapboxgl.accessToken = NODE_ENVS.configuration.mapboxToken;
const Map = class Map extends ReactQueryParams {
  markers = [];

  mapLegends = [];

  activeDots = [];

  clickedCountry = false;

  selectedPathogen = null;

  maxValue = 0;

  mouseClicked = false;

  currentHoveredCountry = '';

  mapPopupContainer = null;

  mapTooltipcontainer = null;

  dataGroupedPerCountry = {};

  componentDidMount() {
    setMapAsLoaded(true);
    setPageLoading(true);

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/metabiota/cl8ub0j4n000215kiy9re1364',
      attributionControl: false,
      center: mapDefaults.center,
      dragRotate: false,
      interactive: true,
      maxBounds: [[-180, -85], [180, 85]],
      maxZoom: mapDefaults.maxZoom,
      minZoom: mapDefaults.minZoom,
      pitchWithRotate: false,
      showCompass: false,
      zoom: mapDefaults.zoom,
      cooperativeGestures: isIFrameView(),
    });

    this.map.on('load', this.registerMapEvents);
    this.map.on('dragstart', this.mapDragStartCallback);
    // back/forward button hits here
    window.onpopstate = this.onpopstate;
  }

  onpopstate = () => {
    const urlParams = getDataFromUrl();

    if (urlParams.countryCode) {
      selectCountry(urlParams.countryCode);
      // set the new position
      store.dispatch(setPopupParams({
        type: 'manual',
        lngLat: {
          lat: countryCoordinates[urlParams.countryCode][0],
          lng: countryCoordinates[urlParams.countryCode][1],
        },
      }));
    } else {
      // deselect and clear if no country available in URL
      deselectCountry();
      openPopup(false);
    }
  };

  mapDragStartCallback() {
    if (isMobileView()) {
      this.countriesLeaveCallback();
    }
  }

  componentDidUpdate() {
    const {
      loaded, data, selectedPathogen, showNotification,
    } = this.props;
    if (!showNotification) {
      this.map.resize();
    }

    if (loaded) {
      if (selectedPathogen) {
        if (!this.selectedPathogen || this.selectedPathogen.value !== selectedPathogen.value) {
          if (this.selectedPathogen) {
            this.closePopupCallback(); // hide the popup if opened
          }
          // if new pathogen hits in, re-update the data
          this.selectedPathogen = selectedPathogen;
          this.activeDots = [];

          store.dispatch(fetchOutbreaks(this.selectedPathogen.value));
        } else if (!Object.is(this.data, data)) {
          // if the same pathogen is available, means the data has changed
          this.updateMap(data);
        }
      } else {
        // initial request
        store.dispatch(fetchPathogens());
      }
    }

    return null;
  }

  componentWillUnmount() {
    window.onpopstate = {}; // clear state
    this.map = null;
  }

  addMakersOnMap = (dot) => {
    const countryCoords = countryCoordinates[dot.countryCode];
    if (countryCoords) {
      const el = document.createElement('div');
      ReactDOM.render(<PulsatingDot />, el);
      // add marker to map
      this.markers.push(new mapboxgl.Marker(el)
        .setLngLat([countryCoords[1], countryCoords[0]])
        .addTo(this.map));
    }
  };

  setFill() {
    this.clearMarkers();

    const { data } = this.props;

    this.dataGroupedPerCountry = {};
    const recentData = data.eventsPerCountry.filter((d) => {
      this.dataGroupedPerCountry[d.countryCode] = d;

      return d.hasRecentEvent;
    });

    this.activeDots = recentData;

    // add markers to map
    recentData.forEach(this.addMakersOnMap);
    const colors = heatMapColors.default;
    const highlightedColors = heatMapColors.hovered;

    this.map.setPaintProperty('country-colors', 'fill-color', ['match', ['get', 'ISO']]
      .concat(this.getPaintProps(data.eventsPerCountry, colors)));
    this.map.setPaintProperty('country-highlighted', 'fill-color', ['match', ['get', 'ISO']]
      .concat(this.getPaintProps(data.eventsPerCountry, highlightedColors)));
  }

  getColorIndexFor(v, length) {
    return this.maxValue === v
      ? length - 1
      : parseInt(Math.ceil(v / (this.maxValue / length)) - 1, 10);
  }

  getPaintProps(values, colors) {
    // sort list to get the max. O(nlogn)
    let sortedValues = [];
    const cLength = colors.length;

    if (values.length) {
      sortedValues = values.sort((a, b) => {
        if (a.numberOfEvents > b.numberOfEvents) {
          return -1;
        }
        if (a.numberOfEvents < b.numberOfEvents) {
          return 1;
        }

        return 0;
      });

      this.maxValue = sortedValues[0].numberOfEvents;

      return sortedValues.reduce((arr, x) => [
        x.countryCode,
        colors[this.getColorIndexFor(x.numberOfEvents, cLength - 1)],
      ].concat(arr),
      [colors[cLength - 1]]); // default color must remain at the end;
    }

    // fill all countries with the default color. required 4 params to be sent to the api
    return ['NOT_FOUND', colors[cLength - 1], colors[cLength - 1]];
  }

  mapTooltip = (event) => {
    if (!this.mouseClicked && event) {
      const mapContainer = this.map.getContainer();
      const cCode = event.features[0].properties.ISO;
      const countryData = this.dataGroupedPerCountry[cCode];
      const container = this.mapTooltipcontainer.children[0];

      container.children[0].textContent = countries[cCode];
      container.children[0].style['font-weight'] = 600;

      if (countryData && countryData.numberOfEvents) {
        container.children[1].textContent = this.props.translate('mapbox.has_data', {
          epidemicsNumber: countryData.numberOfEvents,
        });
        if (countryData.hasRecentEvent) {
          container.children[2].style.display = 'block';
        } else {
          container.children[2].style.display = 'none';
        }

        if (isMobileView()) {
          this.mapTooltipcontainer.style.visibility = 'hidden';
        } else {
          this.mapTooltipcontainer.style.visibility = 'visible';
        }
      } else {
        container.children[1].textContent = this.props.translate('mapbox.no_data', {
          pathogen: this.props.selectedPathogen ? this.props.selectedPathogen.value : false,
        });
        container.children[2].style.display = 'none';

        this.mapTooltipcontainer.style.visibility = 'visible';
      }

      const tooltipPos = getMapTooltipPosition(
        mapContainer, {
          clientWidth: this.mapTooltipcontainer.clientWidth,
          clientHeight: this.mapTooltipcontainer.clientHeight,
        },
        event.originalEvent,
      );

      container.classList.remove('top-left', 'top-right', 'bottom-left', 'bottom-right');
      container.classList.add(`${tooltipPos.arrowPos.vertical}-${tooltipPos.arrowPos.horizontal}`);

      Object.assign(
        this.mapTooltipcontainer.style,
        {
          ...tooltipPos.styleProps,
        },
      );
    } else {
      this.mapTooltipcontainer.style.visibility = 'hidden';
    }
  };

  countriesChangeCallback = (e) => {
    this.mapTooltip(e);

    const cCode = e.features[0].properties.ISO;

    if (this.dataGroupedPerCountry[cCode]) {
      this.map.getCanvas().style.cursor = 'pointer';
    } else {
      this.map.getCanvas().style.cursor = 'default';
    }

    if (this.currentHoveredCountry !== cCode) {
      this.currentHoveredCountry = cCode;

      // make sure the clicked country remains highlighted
      this.map.setFilter('country-highlighted', ['in', 'ISO', cCode, this.clickedCountry]);
    }
  };

  countriesLeaveCallback = () => {
    this.mapTooltip();

    this.currentHoveredCountry = null;
    this.map.getCanvas().style.cursor = '';
    // keep the clicked country highlighted
    this.map.setFilter('country-highlighted', ['==', 'ISO', this.clickedCountry]);
  };

  clearMarkers() {
    this.markers.forEach(marker => marker.remove());
    this.markers = [];
  }

  updateMap(data) {
    this.data = data;

    this.clearMapLegends();

    if (data) {
      this.setFill();
    }

    this.mapLegends = MapLegends(this.activeDots.length);
    this.mapLegends.forEach(legend => this.map.addControl(legend, 'bottom-left'));

    setPageAsLoadedDispatch();
    setPageLoading();
  }

  clearMapLegends() {
    this.mapLegends.forEach(control => this.map.removeControl(control));
  }

  registerMapEvents = () => {
    setMapAsLoaded();

    // convert object to array and structure it in a simple array
    const countryMatchers = Object.entries(countries).flatMap(x => x);
    countryMatchers.push(this.props.translate('mapbox.unknown_location'));

    this.map.setLayoutProperty(
      'country-label-sm',
      'text-field',
      ['match', ['get', 'iso_3166_1'], ...countryMatchers],
    );
    this.map.setLayoutProperty(
      'country-label-md',
      'text-field',
      ['match', ['get', 'iso_3166_1'], ...countryMatchers],
    );
    this.map.setLayoutProperty(
      'country-label-lg',
      'text-field',
      ['match', ['get', 'iso_3166_1'], ...countryMatchers],
    );

    this.mapTooltipcontainer = document.createElement('div');
    this.mapTooltipcontainer.style.position = 'absolute';
    this.mapTooltipcontainer.className = 'tippy-tooltip tippy-notransition mb-map-tooltip';

    this.mapPopupContainer = document.createElement('div');
    this.mapPopupContainer.style.position = 'absolute';
    this.mapPopupContainer.className = 'mapboxgl-popup mb-map-popup mapboxgl-popup-anchor-bottom';
    this.mapContainer.appendChild(this.mapPopupContainer);

    ReactDOM.render(
      <div>
        <div />
        <div />
        <div className="color-warning">{this.props.translate('mapbox.new_data')}</div>
      </div>, this.mapTooltipcontainer,
    );

    document.querySelector('main').appendChild(this.mapTooltipcontainer);

    const zoomInstance = new ZoomControl();
    this.map.addControl(zoomInstance, 'top-left');
    this.map.addControl(new ResetZoomControl(this.map), 'top-left');

    this.map.on('mousemove', 'country-colors', this.countriesChangeCallback);
    this.map.on('mouseleave', 'country-colors', this.countriesLeaveCallback);

    this.map.on('zoomstart', zoomInstance.handleZoomStart);
    this.map.on('zoomend', zoomInstance.handleZoomEnd);

    this.map.on('click', 'country-colors', this.countryClickCallback);

    this.map.on('mousedown', 'country-colors', this.mouseDownCallback);
    this.map.on('mouseup', 'country-colors', this.mouseUpCallback);
    this.map.on('move', this.mapMoveCallback);

    // if there's a country code on map initialization, auto-trigger the popup
    if (this.props.selectedCountry) {
      setTimeout(() => {
        this.autoTriggerPopup(this.props.selectedCountry);
      }, 1000);
    }
  };

  mapMoveCallback = (e) => {
    store.dispatch(setPopupParams(e));
  };

  autoTriggerPopup = (cCode) => {
    // get the center coordinates for the requested country
    const coords = countryCoordinates[cCode];
    if (coords) {
      this.currentHoveredCountry = cCode;
      // trigger popup
      this.countryClickCallback({
        type: 'manual',
        lngLat: {
          lat: coords[0],
          lng: coords[1],
        },
        features: [{ properties: { ISO: cCode } }],
      });
    } else {
      setAppUrl(null, this.props.selectedPathogen);
    }
  };

  mouseDownCallback = () => {
    this.mouseClicked = true;
    this.mapTooltip();
  };

  mouseUpCallback = (e) => {
    this.mouseClicked = false;
    this.mapTooltip(e);
  };

  countryClickCallback = (e) => {
    const cCode = e.features[0].properties.ISO;

    if (this.currentHoveredCountry !== cCode) {
      return false;
    }
    if (this.dataGroupedPerCountry[cCode] && this.dataGroupedPerCountry[cCode].numberOfEvents) {
      selectCountry(cCode);
      setAppUrl(cCode, this.props.selectedPathogen);
      openPopup(true);
      setTimeout(() => {
        store.dispatch(setPopupParams(e));
      });
      store.dispatch(closePanel());
      store.dispatch(selectedCountryData(
        cCode,
        this.props.selectedPathogen ? this.props.selectedPathogen.value : false,
      ));

      if (window.innerWidth > 480) {
        this.mapTooltip();

        ReactDOM.render(
          <Provider store={store}>
            <PopupContent
              map={this.map}
              mapContainer={this.mapContainer}
              mapPopupContainer={this.mapPopupContainer}
              mapContent={e.features[0].properties}
              onCloseCallback={this.closePopupCallback}
            />
          </Provider>,
          this.mapPopupContainer,
        );
        trackEvent('CAT_MAP', {
          prefix: '',
          name: 'OPEN',
          suffix: `${cCode} ${this.selectedPathogen.label}`,
        }, {
          prefix: '',
          name: 'OPEN',
          suffix: 'the popup from map',
        });
        this.clickedCountry = cCode;
      } else {
        trackEvent('CAT_MAP', {
          prefix: '',
          name: 'OPEN',
          suffix: `${cCode} ${this.selectedPathogen.label}`,
        }, {
          prefix: '',
          name: 'OPEN',
          suffix: 'the modal from map',
        });
        toggleCountryModal(true);
      }
      modalView(`country-${cCode}`, 'WITH_EVENTS', this.selectedPathogen.label);
    } else {
      deselectCountry();
      setAppUrl(null, this.props.selectedPathogen);
      openPopup(false);
      modalView(`country-${cCode}`, 'WITHOUT_EVENTS', this.selectedPathogen.label);
      trackEvent('CAT_MAP', {
        prefix: '',
        name: 'CLICK',
        suffix: `Country ${cCode} ${this.selectedPathogen.label}`,
      }, {
        prefix: '',
        name: 'CLICKED',
        suffix: 'the popup from map',
      });
      toggleCountryModal(false);
    }

    return true;
  };

  closePopupCallback = () => {
    if (!this.currentHoveredCountry) {
      setAppUrl(null, this.props.selectedPathogen);
    }
    deselectCountry();

    if (this.props.countryData) {
      trackEvent('CAT_MAP', {
        prefix: '',
        name: 'CLOSE',
        suffix: `${this.props.countryData && this.props.countryData.countryCode} ${this.props.selectedPathogen.label}`,
      }, {
        prefix: '',
        name: 'CLOSE',
        suffix: 'the popup from map',
      });
    }
    if (this.clickedCountry !== this.currentHoveredCountry) {
      this.clickedCountry = false;
      this.map.setFilter('country-highlighted', ['==', 'ISO', '']);
    } else {
      this.map.setFilter('country-highlighted', ['==', 'ISO', this.clickedCountry]);
    }

    openPopup(false);
  };

  render() {
    return (
      <div className="full-height">
        <div className="full-height" ref={(el) => { this.mapContainer = el; }} />
        <PopupModalContent onRef={ref => (this.child = ref)} />
        <Seo
          title={this.props.translate('seo.home.title', {
            pathogenName: this.props.selectedPathogen && this.props.selectedPathogen.pathogenName
              ? ` - ${this.props.selectedPathogen.pathogenName}`
              : null,
          })}
        />
        <Footer />
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    data: state.mapReducers.data,
    loaded: state.mapReducers.loaded,
    pathogens: state.mapReducers.pathogensList,
    countryData: state.mapReducers.countryData,
    selectedPathogen: state.pathogenReducers.selectedPathogen,
    showNotification: state.globalReducers.showNotification,
    pageLoading: state.globalReducers.pageLoading,
    selectedCountry: state.mapReducers.selectedCountry,
  };
}

export default connect(mapStateToProps)(Map);
