/* please add other generic constants here */
export const MODAL_TYPE = {
  DEFAULT: 'default',
  ERROR: 'error',
  MAP: 'map',
};

export const NOTIFICATION_BAR = {
  COOKIE_NAME: 'notificationBarDismissed',
  LSTORAGE_REPORT_DATE: 'reportDate',
};

export const CLOUDFRONT_COOKIES = {
  KEY_PAIR_ID: 'CloudFront-Key-Pair-Id',
  POLICY: 'CloudFront-Policy',
  SIGNATURE: 'CloudFront-Signature',
};

export const ONBOARDING = {
  COOKIE_NAME: 'onboardingRemoved',
};
