import React from 'react';
import { withNamespaces } from 'react-i18next';
import { openPanel } from '../../redux/actions/map-actions';
import store from '../../redux/store';

/**
 * Component to render the info circle
 */
class InfoCircle extends React.Component {
  openMapFilters = () => {
    store.dispatch(openPanel('mapFilters'));
  };

  render() {
    const { infoCircle, t } = this.props;

    return (
      <div className="info-wrapper">
        <div className="info">
          <div className="info-data">
            <div className="mobile-data">
              <div>
                <div className="events-number">{ infoCircle.eventsNumber }</div>

                <div className="events-number-label">
                  {infoCircle.eventsNumber > 1 ? (t('infoCircle.trackedEpidemics.plural')) : (t('infoCircle.trackedEpidemics.singular')) }<br />
                  {t('infoCircle.trackedEpidemics.tracked')} {t('infoCircle.trackedEpidemics.globally')}
                </div>
                <div> &nbsp; </div>
              </div>
              <div>
                <div>{t('infoCircle.trackedEpidemics.selected', {
                  selected: infoCircle.isPathogenSelected ? 1 : infoCircle.pathogensNo,
                  total: infoCircle.pathogensNo,
                })}
                </div>
                <button
                  type="button"
                  className="btn-link normal-font-weight no-padding text popup-link"
                  onClick={() => this.openMapFilters()}
                >
                  {t('infoCircle.trackedEpidemics.pathogenLink')}
                </button>
              </div>
            </div>
            <div className="desktop-data">
              <div className="events-number">{ infoCircle.eventsNumber }</div>
              <div className="events-number-label">
                {infoCircle.eventsNumber > 1 ? (t('infoCircle.trackedEpidemics.plural')) : (t('infoCircle.trackedEpidemics.singular')) }<br />
                {t('infoCircle.trackedEpidemics.tracked')} {t('infoCircle.trackedEpidemics.globally')}
              </div>
              <div className="separator"> &nbsp; </div>
              <div>{t('infoCircle.trackedEpidemics.selected', {
                selected: infoCircle.isPathogenSelected ? 1 : infoCircle.pathogensNo,
                total: infoCircle.pathogensNo,
              })}
              </div>
              <button
                type="button"
                className="btn-link normal-font-weight no-padding text popup-link"
                onClick={() => this.openMapFilters()}
              >
                {t('infoCircle.trackedEpidemics.pathogenLink')}
              </button>
            </div>
          </div>
        </div>

        <div className="pointer" />
      </div>
    );
  }
}

export default withNamespaces()(InfoCircle);
