/* eslint-disable import/no-extraneous-dependencies */
/*
  issue with react-hot-loader
  even though those 2 dependencies are only used in development
  eslint has no way to tell that and outputs an error
*/

// react dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { hotjar } from 'react-hotjar';

// hot reload for development
import { hot } from 'react-hot-loader';

import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import axios from 'axios';

import store from './redux/store';
import './assets/stylesheets/fonts.css';
import '../less/style.less';
import './i18n';

import App from './App';

const root = document.getElementById('root') || document.createElement('div');

Modal.setAppElement('#root');
axios.defaults.withCredentials = true;
axios.defaults.baseURL = `${NODE_ENVS.configuration.apiHost}/app/gemmxl/v1`;

const render = (Component) => {
  if (NODE_ENVS.configuration.hotjar) {
    hotjar.initialize(
      NODE_ENVS.configuration.hotjar.hjid,
      NODE_ENVS.configuration.hotjar.hjsv,
    );
  }

  ReactDOM.render(
    <CookiesProvider>
      <Provider store={store}>
        <Component />
      </Provider>
    </CookiesProvider>,
    root,
  );
};

render(hot(module)(App));


export default render;
