import React from 'react';
import { withNamespaces } from 'react-i18next';
import { getFormattedDate } from '../../../utils';

const FormattedDate = ({
  timestamp, format, utc, classNames, t,
}) => (
  <span className={classNames}>{getFormattedDate(timestamp, format, utc, t)}</span>
);

export default withNamespaces()(FormattedDate);
