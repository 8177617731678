import { ShareButton } from 'react-custom-share';
import React from 'react';
import classNames from 'classnames';
import { trackEvent } from '../../analytics';

export default class SocialShare extends React.Component {
  state = {
    facebook: false,
    twitter: false,
    linkedin: false,
  };

  hoverButton = (network) => {
    this.setState({
      facebook: false,
      twitter: false,
      linkedin: false,
    });

    this.setState({
      [network]: true,
    });
  };

  trackButtonClick = (network) => {
    trackEvent(
      'CAT_FOOTER',
      {
        prefix: '',
        name: 'CLICK',
        suffix: `on ${network} share icon`,
      },
      {
        prefix: '',
        name: 'OPEN',
        suffix: `popup window to share on ${network}`,
      },
    );
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.hoverButton, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.hoverButton, false);
  }

  render() {
    const { t } = this.props;
    const domainName = `${NODE_ENVS.configuration.domainName}`;
    const imageUrl = 'https://metabiota-frontend-gemmxl-media.s3-us-west-2.amazonaws.com/social-image.png';

    const longShareButtonProps = {
      url: domainName,
      media: imageUrl,
      longtext: t('shareParams.message'),
    };
    const shareButtonProps = {
      url: domainName,
      media: imageUrl,
      text: t('shareParams.message'),
    };

    return (
      <ul className="list-inline right-align social-share">
        <li className="text">
          <span>{t('social.share_et')}</span>
        </li>
        {/* eslint-disable-next-line */}
        <li
          className="item-facebook"
          onMouseEnter={() => this.hoverButton('facebook')}
          onMouseLeave={() => this.hoverButton()}
          onClick={() => { this.hoverButton(); this.trackButtonClick('facebook'); }}
        >
          <ShareButton {...Object.assign(longShareButtonProps, { network: 'Facebook' })}>
            <span
              role="presentation"
              className={classNames('mb-icon', {
                'mb-icon-facebook': !this.state.facebook,
                'mb-icon-facebook-hover': this.state.facebook,
              })}
            />
          </ShareButton>
        </li>
        {/* eslint-disable-next-line */}
        <li
          className="item-linkedin"
          onMouseEnter={() => this.hoverButton('linkedin')}
          onMouseLeave={() => this.hoverButton()}
          onClick={() => {
            this.hoverButton();
            this.trackButtonClick('linkedin');
          }}
        >
          <ShareButton {...Object.assign(longShareButtonProps, { network: 'Linkedin' })}>
            <span
              role="presentation"
              className={classNames('mb-icon', {
                'mb-icon-linkedin': !this.state.linkedin,
                'mb-icon-linkedin-hover': this.state.linkedin,
              })}
            />
          </ShareButton>
        </li>
        {/* eslint-disable-next-line */}
        <li
          className="item-twitter"
          onMouseEnter={() => this.hoverButton('twitter')}
          onMouseLeave={() => this.hoverButton()}
          onClick={() => {
            this.hoverButton();
            this.trackButtonClick('twitter');
          }}
        >
          <ShareButton {...Object.assign(shareButtonProps, { network: 'Twitter' })}>
            <span
              role="presentation"
              className={classNames('mb-icon', {
                'mb-icon-x-twitter': !this.state.twitter,
                'mb-icon-x-twitter-hover': this.state.twitter,
              })}
            />
          </ShareButton>
        </li>
      </ul>
    );
  }
}
