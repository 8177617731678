import React from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { withCookies } from 'react-cookie';
import GA from './analytics';
import history from './history';
import Routes from './Routes';
import Header from './components/Header/Header';
import GlobalLoader from './components/GlobalLoader';
import ErrorModal from './components/ErrorModal';
import PageLoader from './components/PageLoader';
import NotificationsBar from './components/NotificationsBar';
import store from './redux/store';
import { setPageError } from './redux/actions/global-actions';
import { fetchPathogens } from './redux/actions/pathogen-actions';
import * as Constants from './constants';
import CookiesModal from './components/CookiesModal';
import ContactUsModal from './components/ContactUs/ContactUsModal';

const amazonCookiesUrl = `${NODE_ENVS.configuration.apiHost}/public/check`;
const amazonCookiesUrl1 = `${amazonCookiesUrl}/1`;
const amazonCookiesUrl2 = `${amazonCookiesUrl}/2`;
const amazonCookiesUrl3 = `${amazonCookiesUrl}/3`;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.unmounted = true;
    this.firstCheckDone = false;
    this.cookiesSupported = true;

    if (this.props.googleAnalyticsTrackOptIn) {
      GA.init();
    }

    if (NODE_ENVS.currentEnv === 'development' || NODE_ENVS.currentEnv === 'int') {
      this.props.cookies.set('cookie_support', true);
    } else {
      this.props.cookies.set('cookie_support', true, { sameSite: 'None', secure: true });
    }

    const cookie = this.props.cookies.get('cookie_support');
    if (!cookie) {
      this.cookiesSupported = false;
    }

    this.setFirstCheck(this.checkCookies());
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  setFirstCheck = (v) => {
    if (v && !this.firstCheckDone) {
      this.firstCheckDone = true;
      store.dispatch(fetchPathogens());
    }
  };

  interceptorSuccessCallback = (config) => {
    const c = config;
    if (c.disableCredentials) {
      c.withCredentials = false;
    }

    if (c.url.indexOf('public/check') === -1) {
      if (c.url.indexOf('?r=') === -1 && c.url.indexOf('&r=') === -1) {
        const rv = Math.floor(Math.random() * 1000000);
        if (c.url.indexOf('?') === -1) {
          c.url = `${c.url}?r=${rv}`;
        } else {
          c.url = `${c.url}&r=${rv}`;
        }
      }
    }

    if (c.url.indexOf('public/check') === -1 && !this.checkCookies()) {
      return this.publicCheck(c).then(() => {
        if (!c.headers) return c;
        if (!c.headers.Cookie) return c;
        let cookies = c.headers.Cookie;

        const cookie1 = this.props.cookies.get('CloudFront-Key-Pair-Id');
        if (cookie1 && cookies.indexOf('CloudFront-Key-Pair-Id') === -1) {
          cookies = `${cookies}; CloudFront-Key-Pair-Id=${cookie1}`;
        }
        const cookie2 = this.props.cookies.get('CloudFront-Policy');
        if (cookie2 && cookies.indexOf('CloudFront-Policy') === -1) {
          cookies = `${cookies}; CloudFront-Policy=${cookie2}`;
        }
        const cookie3 = this.props.cookies.get('CloudFront-Signature');
        if (cookie3 && cookies.indexOf('CloudFront-Signature') === -1) {
          cookies = `${cookies}; CloudFront-Signature=${cookie3}`;
        }
        c.headers.Cookie = cookies;
        return c;
      });
    }

    return c;
  };

  interceptorErrorCallback = error => Promise.reject(error);

  componentDidMount() {
    this.unmounted = false;

    axios.interceptors.request.use(this.interceptorSuccessCallback, this.interceptorErrorCallback);

    if (!this.checkCookies()) {
      this.publicCheck();
    }
  }

  randomUrl = (url) => {
    const rv = Math.floor(Math.random() * 1000000);
    return `${url}?r=${rv}`;
  }

  publicCheck = () => axios.post(this.randomUrl(amazonCookiesUrl1))
    .then(() => axios.post(this.randomUrl(amazonCookiesUrl2)))
    .then(() => axios.post(this.randomUrl(amazonCookiesUrl3)))
    .then(() => {
      this.setFirstCheck(true);
    })
    .catch(() => {
      this.setFirstCheck(false);
      store.dispatch(setPageError());
    })

  checkCookies = () => NODE_ENVS.configuration.isDevelopment
      || !!(this.props.cookies.get(Constants.CLOUDFRONT_COOKIES.KEY_PAIR_ID)
      && this.props.cookies.get(Constants.CLOUDFRONT_COOKIES.POLICY)
      && this.props.cookies.get(Constants.CLOUDFRONT_COOKIES.SIGNATURE));

  render() {
    const { loading } = this.props;

    return (
      <Router history={history}>
        <React.Fragment>
          {this.firstCheckDone && this.cookiesSupported
            ? (
              <React.Fragment>
                <Header />
                <main className="full-height no-navbar no-notification-bar">
                  <Routes />
                </main>
                <NotificationsBar />
              </React.Fragment>
            ) : null
            }
          { loading && this.cookiesSupported ? <GlobalLoader /> : null }
          { !this.cookiesSupported ? <CookiesModal /> : null }
          <ErrorModal />
          <ContactUsModal />
          <PageLoader />
        </React.Fragment>
      </Router>
    );
  }
}

function globalStateToProps(state) {
  return {
    loading: state.globalReducers.loading,
    pathogens: state.pathogenReducers.pathogensList,
    googleAnalyticsTrackOptIn: state.globalReducers.googleAnalyticsTrackOptIn,
  };
}
export default withCookies(connect(globalStateToProps)(App));
