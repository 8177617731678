import React from 'react';
import { withNamespaces } from 'react-i18next';
import * as Constants from '../constants';
import ModalDialog from './ModalDialog/ModalDialog';

class CookiesModal extends React.Component {
  reloadWindow = () => {
    window.location.reload();
  };

  render() {
    const { t } = this.props;
    return (
      <ModalDialog
        isOpen
        title="Cookies are disabled"
        type={Constants.MODAL_TYPE.ERROR}
        hideCloseButton
      >
        <div className="cookiesModal">
          <p className="margin-bottom">{t('general.errors.modal_message_cookies_no_enabled')}</p>
          <button
            type="button"
            className="btn btn-primary pull-right margin-bottom"
            onClick={this.reloadWindow}
          >
            {t('general.errors.cookies_not_enabled_button')}
          </button>
        </div>
      </ModalDialog>
    );
  }
}

export default withNamespaces()(CookiesModal);
