import React from 'react';
import { withNamespaces } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactQueryParams from 'react-query-params';
import { trackEvent, modalView } from '../../../analytics';
import countries from '../../../countries';
import FormattedDate from '../../UI/DataFormat/FormattedDate';
import FormattedNumber from '../../UI/DataFormat/FormattedNumber';
import { TooltipDesktop, TooltipMobile } from '../../Tooltip';
import { openPanel, toggleCountryModal } from '../../../redux/actions/map-actions';
import {
  setSelectedPathogenProfile,
} from '../../../redux/actions/pathogen-actions';
import store from '../../../redux/store';
import { isMobileView } from '../../../utils';

class PopupDetailedView extends ReactQueryParams {
  constructor(props) {
    super(props);

    this.state = {
      isOfferingsModalOpened: false,
      isModalOpened: false,
    };
  }

  goToProduct = () => {
    trackEvent('CAT_MAP', {
      prefix: '',
      name: 'CLICK',
      suffix: 'LEARN MORE',
    }, {
      prefix: '',
      name: 'CLICK',
      suffix: 'on the button Learn More from offerings',
    });
    window.open('https://www.ginkgobiosecurity.com/');
  };

  toggleModalDialog = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (!this.state.isModalOpened) {
      modalView('coronavirus-important-note');
      trackEvent('CAT_MAP', {
        prefix: '',
        name: 'OPEN',
        suffix: 'Novel Coronavirus Important Note Modal',
      }, {
        prefix: '',
        name: 'OPEN',
        suffix: 'Novel Coronavirus Important Note Modal',
      });
    } else {
      trackEvent('CAT_MAP', {
        prefix: '',
        name: 'CLOSE',
        suffix: 'Novel Coronavirus Important Note Modal',
      }, {
        prefix: '',
        name: 'CLOSE',
        suffix: 'Novel Coronavirus Important Note Modal',
      });
    }

    this.setState(prevState => ({
      isModalOpened: !prevState.isModalOpened,
    }));
  };

  showPathogenProfile = (pathogenName) => {
    store.dispatch(setSelectedPathogenProfile(pathogenName));

    store.dispatch(openPanel('pathogenProfiles'));
    trackEvent('CAT_MAP', {
      prefix: '',
      name: 'CLICK',
      suffix: `view pathogen ${pathogenName} profile`,
    }, {
      prefix: '',
      name: 'SELECT',
      suffix: 'pathogen profile from tooltip',
    });
  };

  getPathogenDetails(pathogenData) {
    const { t } = this.props;
    const characteristics = ['firstReportDate', 'latestReportDate', 'reportedCases', 'reportedDeaths'];

    return characteristics.map(characteristic => (
      <div className="pathogen-data valign-baseline" key={characteristic}>
        <div className="col-xs-7 no-padding font-size-small">
          {t(`countryPopup.details.${characteristic}`)}
        </div>
        <div className="col-xs-5 no-padding right-align bold-font-weight">
          {characteristic === 'firstReportDate' || characteristic === 'latestReportDate'
            ? <FormattedDate timestamp={pathogenData[characteristic]} format="MM/DD/YY" />
            : <FormattedNumber number={pathogenData[characteristic] || 0} format="comma" />
                  }
        </div>
      </div>
    ));
  }

  getEventContent(pathogenData) {
    const { t, pathogensList } = this.props;
    const { hasProfile } = pathogensList
      .filter(pathogen => pathogen.pathogenName === pathogenData.pathogenName)[0];

    const pathogenTooltipContent = (
      <button
        type="button"
        className="btn-link normal-font-weight no-padding text popup-link"
        onClick={() => this.showPathogenProfile(pathogenData.pathogenName)}
      >
        {t('countryPopup.details.viewProfile')}
      </button>
    );

    const siInfoTooltip = (
      <div>
        {t('infoTooltip.popupDetails.sentimentIndex')}
        <button
          type="button"
          className="btn-link normal-font-weight no-padding text popup-link"
          onClick={this.openContactUs}
        >
          {t('infoTooltip.popupDetails.contact_us')}
        </button>
      </div>
    );

    return (
      <div className="event-details">
        <div className="semibold-font-weight d-inline-flex event-name valign-center">
          {pathogenData.pathogenName}
          {hasProfile ? (
            <div className="info-box profile-info padding-left-small hidden-xs hidden-sm font-size-medium">
              <TooltipDesktop
                content={pathogenTooltipContent}
                placement="top-start"
              >
                <span className="mb-icon mb-icon-info-icon" />
              </TooltipDesktop>
            </div>
          ) : null}
        </div>
        <div className="event-origin font-size-small">
          { pathogenData.sparkLocation || pathogenData.sparkDate || pathogenData.sparkYear
            || pathogenData.aditionalIdentifier
            ? t('countryPopup.details.eventOrigin.origin')
            : '' }

          {/* location */}
          { pathogenData.sparkLocation
            ? <span> {t('countryPopup.details.eventOrigin.location')} <b> { pathogenData.sparkLocation }</b></span>
            : '' }

          {/* date */}
          {pathogenData.sparkDate || pathogenData.sparkYear
            ? (
              <span>
                { t('countryPopup.details.eventOrigin.date')}
                <b>
                  { pathogenData.sparkDate
                    ? <FormattedDate timestamp={pathogenData.sparkDate} format="Month YYYY" />
                    : pathogenData.sparkYear
              }
                </b>
              </span>
            )
            : '' }

          {/* link to */}
          {pathogenData.additionalIdentifier ? (
            <span>
              {t('countryPopup.details.eventOrigin.link')}
              {pathogenData.additionalIdentifier.toLowerCase() === 'unknown'
                ? t('countryPopup.details.eventOrigin.unknownLink')
                : `"${pathogenData.additionalIdentifier}"`}
            </span>
          )
            : '' }
          { pathogenData.sparkLocation || pathogenData.sparkDate
            || pathogenData.sparkYear || pathogenData.aditionalIdentifier ? '. ' : ''}

          {/* source */}
          {t('countryPopup.details.eventOrigin.reportedBy')} {pathogenData.reportingSource }
          {pathogenData.reportingSource.toLowerCase() === 'composite sources'
            ? ` (${t('countryPopup.details.concentricBestSource')}).`
            : '.'
          }
        </div>
        {this.getPathogenDetails(pathogenData)}
        {pathogenData.pathogenSentimentIndex ? (
          <div className="pathogen-data valign-baseline">
            <div className="col-xs-7 no-padding d-inline-flex font-size-small">
              {t('countryPopup.details.pathogenSentimentIndex')}
              <div className="info-box sentiment-index-info padding-left-small">
                <TooltipDesktop content={siInfoTooltip} placement="top-start">
                  <span className="mb-icon mb-icon-info-icon font-size-medium" />
                </TooltipDesktop>
                <TooltipMobile content={siInfoTooltip}>
                  <span className="mb-icon mb-icon-info-icon font-size-medium" />
                </TooltipMobile>
              </div>
            </div>
            <div className="col-xs-5 no-padding right-align bold-font-weight">
              {pathogenData.pathogenSentimentIndex}
            </div>
          </div>
        )
          : null
        }
      </div>
    );
  }

  indexedPathogens = {};

  getDataFromGit = () => {
    // track to google analytics this activity
    // also attach the country name for the opened popup
    trackEvent('CAT_MAP', {
      prefix: '',
      name: 'CLICK',
      suffix: 'Redirected to epidemic data github link from tooltip for '
      + `${countries[this.props.countryData.countryCode]} country`,
    }, {
      prefix: '',
      name: 'CLICK',
      suffix: 'Redirected to epidemic data github link from tooltip',
    });
    // open the github page in a separate tab
    window.open('https://data.humdata.org/dataset/2019-novel-coronavirus-cases', '_blank');
  };

  openContactUs = () => {
    this.setState({
      isOfferingsModalOpened: false,
    });

    if (isMobileView()) {
      toggleCountryModal(false);
    }

    store.dispatch({ type: 'OPEN_CONTACT_US', open: true });
    trackEvent('CAT_MAP', {
      prefix: '',
      name: 'CLICK',
      suffix: 'Contact Metabiota',
    }, {
      prefix: '',
      name: 'CLICK',
      suffix: 'Contact Metabiota link on popup header',
    });
  };

  getContent() {
    const {
      t, countryData, onCloseCallback, closeClassname,
    } = this.props;

    let hasCoronavirus = false;
    const coronavirusString = 'novel coronavirus';

    countryData.events.forEach((e) => {
      const isCoronavirus = e.pathogenName
        .toLowerCase()
        .includes(coronavirusString);
      if (!hasCoronavirus && isCoronavirus) {
        hasCoronavirus = true;
      }
    });
    this.indexedPathogens = {};

    const countryEvents = countryData.events
      && countryData.events
        .sort((a, b) => b.latestReportDate - a.latestReportDate)
        .map(countryEvent => (
          <React.Fragment key={Math.floor(Math.random() * Math.floor((new Date()).getTime()))}>
            <div className="event-content">
              {this.getEventContent(countryEvent)}
            </div>
            <hr />
          </React.Fragment>
        ));

    return (
      this.state.isModalOpened
        ? (
          <div className="popup-details">
            <div className="popup-header">
              <div className="popup-title">
                <button
                  type="button"
                  className="close back pull-left"
                  onClick={this.toggleModalDialog}
                >
                  <span className="mb-icon mb-icon-back padding-right-small" />
                </button>
                <div className="semibold-font-weight text-uppercase">
                  <span
                    title={t('countryPopup.details.title')}
                  >
                    {t('countryPopup.details.coronavirus_note')}
                  </span>
                </div>
              </div>
              <div
                role="presentation"
                className={closeClassname}
                onClick={onCloseCallback}
              >
                <span className="mb-icon mb-icon-dismiss" />
              </div>
            </div>
          </div>
        ) : (
          <div className="popup-details">
            <div className="popup-header">
              <div className="popup-title">
                <div className="semibold-font-weight text-uppercase">
                  <TooltipMobile
                    className="visible-sm"
                    content={`${countries[countryData.countryCode]} ${t('countryPopup.details.title')}`}
                  >
                    <span>
                      {countries[countryData.countryCode]} {t('countryPopup.details.title')}
                    </span>
                  </TooltipMobile>
                  <div
                    className="hidden-sm hidden-xs truncate"
                    title={`${countries[countryData.countryCode]} ${t('countryPopup.details.title')}`}
                  >
                    {countries[countryData.countryCode]} {t('countryPopup.details.title')}
                  </div>
                </div>
                <div
                  title={t('countryPopup.details.subtitle')}
                >{t('countryPopup.details.subtitle')} ({countryData.numberOfEvents})
                </div>
                <i
                  className="font-size-small padding-top-xsmall"
                  title={t('countryPopup.details.contactConcentric')}
                >
                  {t('countryPopup.details.dataInquiry')}
                  <button
                    type="button"
                    className="btn-link normal-font-weight no-padding pathogen-data popup-link"
                    onClick={this.openContactUs}
                  >
                    {t('countryPopup.details.contactConcentric')}
                  </button>
                </i>
              </div>
              <div
                role="presentation"
                className={closeClassname}
                onClick={onCloseCallback}
              >
                <span className="mb-icon mb-icon-dismiss" />
              </div>
            </div>
            <PerfectScrollbar>
              <div className="popup-content no-right-padding no-left-padding">
                {countryEvents}
              </div>
            </PerfectScrollbar>
          </div>
        )
    );
  }

  render() {
    return this.getContent();
  }
}

export default withNamespaces()(PopupDetailedView);
