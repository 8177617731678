import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import * as Constants from '../constants';
import ModalDialog from './ModalDialog/ModalDialog';
import { hidePageError } from '../redux/actions/global-actions';
import pipe from '../utils';

const ErrorModal = ({ error, t }) => (
  <ModalDialog
    isOpen={error}
    title="Server Error"
    type={Constants.MODAL_TYPE.ERROR}
    closeModal={hidePageError}
  >
    <p>{t('general.errors.modal_message_server_side')}</p>
  </ModalDialog>
);

function errorStateToProps(state) {
  return {
    error: state.globalReducers.error,
  };
}

export default pipe(withNamespaces(), connect(errorStateToProps))(ErrorModal);
