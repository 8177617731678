import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import ModalDialog from '../ModalDialog/ModalDialog';
import * as Constants from '../../constants';
import { toggleContactUs } from '../../redux/actions/global-actions';
import pipe, { validateElement } from '../../utils';
import Input from '../UI/Input/Input';
import { trackEvent } from '../../analytics';
import store from '../../redux/store';
import { submitContactForm } from '../../redux/actions/form-actions';
import MetabiotaOfferings from '../../containers/MetabiotaOfferings';

class ContactUsModal extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = this.initState(t);
  }

  initState = t => ({
    showOfferings: false,
    contactForm: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: t('contact.namePlaceholder'),
          label: t('contact.name'),
        },
        value: '',
        validation: {
          required: true,
          maxLength: 255,
        },
        validationMessages: {
          required: t('contact.validationMessages.required'),
          maxLength: t('contact.validationMessages.messageMaxLength'),
        },
        errorMessage: '',
        valid: false,
        touched: false,
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: t('contact.emailPlaceholder'),
          label: t('contact.email'),
        },
        value: '',
        validation: {
          required: true,
          isEmail: true,
          maxLength: 50,
        },
        validationMessages: {
          required: t('contact.validationMessages.required'),
          isEmail: t('contact.validationMessages.emailIncorrect'),
          maxLength: t('contact.validationMessages.messageMaxLength50'),
        },
        errorMessage: '',
        valid: false,
        touched: false,
      },
      message: {
        elementType: 'textarea',
        elementConfig: {
          type: 'text',
          placeholder: t('contact.messagePlaceholder'),
          label: t('contact.message'),
          rows: 4,
        },
        value: '',
        validation: {
          required: true,
          maxLength: 500,
        },
        validationMessages: {
          required: t('contact.validationMessages.required'),
          maxLength: t('contact.validationMessages.messageMaxLength500'),
        },
        errorMessage: '',
        valid: false,
        touched: false,
      },
      consentCheckbox: {
        elementType: 'checkbox',
        elementConfig: {
          label: [
            t('contact.consentLabelText1'),
            <a
              href="https://www.ginkgobiosecurity.com/privacy-policy/"
              className="btn-link-default"
              target="_blank"
              rel="noopener noreferrer"
              key="1"
            >
              {t('contact.consentLabelLink')}
            </a>,
            t('contact.consentLabelText2'),
          ],
          disabled: false,
          class: 'no-bottom-margin',
        },
        validation: {
          required: true,
        },
        validationMessages: {
          required: t('contact.validationMessages.consent'),
        },
        value: false,
        valid: true,
        touched: false,
        hideLabel: true,
      },
    },
    loading: false,
  });

  formSubmitHandler = (event) => {
    const formData = {};
    event.preventDefault();
    let valid = true;
    const fieldsToCheck = [];

    Object.keys(this.state.contactForm).forEach((key) => {
      const formElem = this.state.contactForm[key];

      if (formElem.value) {
        formData[key] = formElem.value;
      }
      if (!this.state.contactForm[key].hidden && this.state.contactForm[key].validation.required) {
        fieldsToCheck.push(key);
      }
    });

    fieldsToCheck.forEach((identifier) => {
      valid = this.validateIndividualElement(identifier) && valid;
    });

    if (!valid) {
      trackEvent(
        'CAT_CONTACT',
        {
          prefix: '',
          name: 'CLICK',
          suffix: 'send message error',
        },
        {
          prefix: '',
          name: 'CLICK',
          suffix: 'send message with errors',
        },
      );
      return false;
    }

    this.setState({ loading: true });

    delete formData.consentCheckbox;

    store.dispatch(submitContactForm(formData, this.closeModal, this.tryAgainCallback));

    trackEvent(
      'CAT_CONTACT',
      {
        prefix: '',
        name: 'CLICK',
        suffix: 'send message success',
      },
      {
        prefix: '',
        name: 'CLICK',
        suffix: 'send message button successfully',
      },
    );

    return true;
  };

  tryAgainCallback = (formData, resetForm, tryAgain) => () => {
    store.dispatch(submitContactForm(formData, resetForm, tryAgain));
  };

  checkboxChangedHandler = (event, checkboxIdentifier) => {
    const contactForm = {
      ...this.state.contactForm,
    };
    const formData = validateElement(contactForm, checkboxIdentifier, event.target.checked);
    formData.formObj[checkboxIdentifier] = formData.updatedFormElement;
    this.setState({ contactForm: formData.formObj });
  };

  inputChangedHandler = (event, formElement) => {
    if (event) {
      const contactForm = {
        ...this.state.contactForm,
      };

      const formData = validateElement(contactForm, formElement.id, event.target.value);
      if (event.type === 'change' && formElement.id === 'email') {
        formData.updatedFormElement.valid = true;
      }
      formData.formObj[formElement.id] = formData.updatedFormElement;

      this.setState({ contactForm: formData.formObj });
    }
  };

  validateIndividualElement = (identifier) => {
    const formState = {
      ...this.state.contactForm,
    };

    const formData = validateElement(formState, identifier);

    formData.formObj[identifier] = formData.updatedFormElement;

    this.setState({ contactForm: formData.formObj });
    return formData.updatedFormElement.valid;
  };

  onRefCallback = key => ref => (this.selects[key] = ref);

  closeModal = () => {
    const { t } = this.props;
    this.setState({ loading: false });
    this.setState(this.initState(t));

    this.setState((prevState) => {
      const contactForm = {
        ...prevState.contactForm,
      };
      Object.keys(contactForm).forEach((key) => {
        contactForm[key].value = '';

        if (contactForm[key].elementType === 'checkbox') {
          contactForm[key].value = false;
        }
      });
      return { contactForm };
    });
    toggleContactUs(false);
    trackEvent(
      'CAT_CONTACT',
      {
        prefix: '',
        name: 'CLICK',
        suffix: 'dismiss contact us modal',
      },
      {
        prefix: '',
        name: 'CLOSE',
        suffix: 'the contact us modal',
      },
    );
  };
  //
  // showOfferings = () => {
  //   trackEvent('CAT_CONTACT', {
  //     prefix: '',
  //     name: 'CLICK',
  //     suffix: 'Metabiota Offerings link',
  //   }, {
  //     prefix: '',
  //     name: 'CLICK',
  //     suffix: 'on the button Metabiota Offerings',
  //   });
  //
  //   this.setState({ showOfferings: true });
  // };
  //
  // handleClickBack = () => {
  //   trackEvent('CAT_CONTACT', {
  //     prefix: '',
  //     name: 'CLICK',
  //     suffix: 'back from Metabiota Offerings',
  //   }, {
  //     prefix: '',
  //     name: 'CLICK',
  //     suffix: 'on the back button from Metabiota Offerings modal',
  //   });
  //   this.setState({ showOfferings: false });
  // };

  render() {
    const { t, showContactUs } = this.props;

    const formElementsArray = [];
    Object.keys(this.state.contactForm).forEach((key) => {
      formElementsArray.push({
        id: key,
        config: this.state.contactForm[key],
      });
    });

    const form = (
      <form onSubmit={this.formSubmitHandler} className={this.state.loading ? 'is-loading' : ''}>
        {formElementsArray.map(formElement => (
          <Input
            key={formElement.id}
            id={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            errorMessage={formElement.config.errorMessage}
            validationMessages={formElement.config.validationMessages}
            hideLabel={formElement.config.hideLabel}
            labelSuffix={formElement.config.labelSuffix}
            hidden={formElement.config.hidden}
            onRefCallback={this.onRefCallback(formElement.id)}
            changed={
              formElement.config.elementType === 'checkbox'
                ? event => this.checkboxChangedHandler(event, formElement.id)
                : event => this.inputChangedHandler(event, formElement)
            }
          />
        ))}

        <div className="contact-us-buttons padding-top-small">
          <button
            className="btn btn-secondary text-uppercase semibold-font-weight col-6"
            type="button"
            onClick={() => this.closeModal()}
          >
            {t('contact.cancel')}
          </button>
          <button
            className="btn btn-primary text-uppercase semibold-font-weight col-6"
            type="submit"
          >
            {t('contact.sendMessage')}
          </button>
        </div>
      </form>
    );
    const modalTitle = this.state.showOfferings
      ? 'Metabiota Offerings'
      : 'Contact Ginkgo Biosecurity';
    const modalContent = this.state.showOfferings ? (
      <MetabiotaOfferings noTitle />
    ) : (
      <div className="contact-form">
        {/* <div className="padding-bottom"> */}
        {/*  {t('contact.offeringsText')} */}
        {/*  <button type="button" className="btn-link normal-font-weight no-padding popup-link"
                    onClick={this.showOfferings}> */}
        {/*    {t('contact.offeringsLink')} */}
        {/*  </button> */}
        {/* </div> */}
        {form}
      </div>
    );
    const modalClass = this.state.showOfferings ? 'no-padding contact-offerings' : '';

    return showContactUs ? (
      <ModalDialog
        isOpen={showContactUs}
        title={modalTitle}
        type={Constants.MODAL_TYPE.DEFAULT}
        closeModal={() => this.closeModal()}
        id="contact-us-modal"
        showBackButton={this.state.showOfferings}
        goBack={this.handleClickBack}
        modalClass={modalClass}
      >
        {modalContent}
      </ModalDialog>
    ) : null;
  }
}

function stateToProps(state) {
  return {
    showContactUs: state.globalReducers.showContactUs,
  };
}

export default pipe(withNamespaces(), connect(stateToProps))(ContactUsModal);
