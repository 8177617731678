import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import AsideBtn from '../components/SidePanel/AsideBtn';
import AsidePage from '../components/SidePanel/AsidePage';
import { openPanel } from '../redux/actions/map-actions';
import pipe from '../utils';
import store from '../redux/store';
import { TooltipSmUp } from '../components/Tooltip';
import * as Constants from '../constants';
import ModalDialog from '../components/ModalDialog/ModalDialog';
import { modalView, trackEvent } from '../analytics';

class SidePanel extends React.Component {
  state = {
    isModalOpened: false,
  };

  toggleModalDialog = () => {
    if (!this.state.isModalOpened) {
      modalView('learn-about-our-data');

      trackEvent('CAT_MAP', {
        prefix: '',
        name: 'OPEN',
        suffix: 'Learn More About Our Data',
      }, {
        prefix: '',
        name: 'OPEN',
        suffix: 'the modal Learn More About Our Data',
      });
      this.setState({
        isModalOpened: true,
      });
    } else {
      trackEvent('CAT_MAP', {
        prefix: '',
        name: 'CLOSE',
        suffix: 'Learn More About Our Data',
      }, {
        prefix: '',
        name: 'CLOSE',
        suffix: 'the modal Learn More About Our Data',
      });
      this.setState({
        isModalOpened: false,
      });
    }
  };

  handleNavigation = (currentOption) => {
    store.dispatch(openPanel(currentOption));
  };

  render() {
    const { t, openedPanel, options } = this.props;
    const selectedOption = options.filter((elem) => {
      if (elem.key === openedPanel) {
        return elem;
      }
      return null;
    })[0];

    return (
      <div className="side-panel">
        <AsidePage
          handleNavigation={this.handleNavigation}
          t={t}
          title={selectedOption && selectedOption.title}
          currentOption={selectedOption}
          {...this.props}
        >
          {selectedOption && selectedOption.component}
        </AsidePage>
        <div className="side-panel-buttons">
          <AsideBtn
            handleNavigation={this.handleNavigation}
            currentOption={selectedOption && selectedOption.key}
            {...this.props}
          />
        </div>
        <TooltipSmUp
          theme="legend"
          className="hidden-xs"
          content={(
            <div>
              <p>{t('mapbox.legends.learnAboutOurData.p_1')}</p>
              <p>{t('mapbox.legends.learnAboutOurData.p_2')}</p>
              <p className="no-margin">{t('mapbox.legends.learnAboutOurData.p_3')}</p>
            </div>
)}
          placement="left-start"
        >
          <div className="learn-more">
            <span className="mb-icon mb-icon-info-icon" />
            <p>{t('mapbox.legends.learnAboutOurData.title')}</p>
          </div>
        </TooltipSmUp>
        <div className="learn-more visible-xs" role="presentation" onClick={this.toggleModalDialog}>
          <span className="mb-icon mb-icon-info-icon" />
          <p>{t('mapbox.legends.learnAboutOurData.title')}</p>
        </div>
        {this.state.isModalOpened
          ? (
            <ModalDialog
              isOpen={this.state.isModalOpened}
              title={t('mapbox.legends.learnAboutOurData.modalTitle')}
              type={Constants.MODAL_TYPE.DEFAULT}
              closeModal={this.toggleModalDialog}
            >
              <div className="model-content">
                <p className="semibold-font-weight">{t('mapbox.legends.learnAboutOurData.p_1')}</p>
                <p>{t('mapbox.legends.learnAboutOurData.p_2')}</p>
                <p className="no-margin">{t('mapbox.legends.learnAboutOurData.p_3')}</p>
              </div>
            </ModalDialog>
          )
          : null
          }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    openedPanel: state.mapReducers.openedPanel,
  };
}
export default pipe(withNamespaces(), connect(mapStateToProps))(SidePanel);
