/* eslint-disable react/prefer-stateless-function */

import React from 'react';
import { withNamespaces } from 'react-i18next';
/*  --- Commented as part of GB-35023 - remove comment to use the download notifications
import store from '../../redux/store';
import { downloadFile, getFormattedDate } from '../../utils';
import { trackEvent } from '../../analytics';
import { submitReportForm } from '../../redux/actions/form-actions';
import { notifyFailure } from '../../redux/actions/notification-actions';
 */
import cgLogo from '../../assets/images/Ginkgo-BS-EpiTracker-Logo.svg';

class NotificationBar extends React.Component {
  /* --- Commented as part of GB-35023 - remove comment to use the download notifications
  state = {
    downloading: false,
  };

  windowReference = null;

  downloadReport = () => {
    this.setState({ downloading: true });
    if (window.navigator.userAgent.indexOf('iPad') > -1) {
      this.windowReference = window.open('about:blank', '_blank');
      store.dispatch(submitReportForm(this.submitMobileCallback));
      this.setState({ downloading: false });
    } else {
      store.dispatch(submitReportForm(this.submitCallback));
    }
  };

  downloadReportMobile = (e) => {
    this.windowReference = window.open('about:blank', '_blank');
    e.preventDefault();
    store.dispatch(submitReportForm(this.submitMobileCallback));
  };

  submitMobileCallback = (data) => {
    trackEvent('CAT_REPORT', {
      prefix: '',
      name: 'OPEN',
      suffix: 'download report bi-weekly',
    }, {
      prefix: '',
      name: 'OPEN',
      suffix: 'download report successfully',
    });
    this.windowReference.location = data.downloadLink;
  };

  submitCallback = (data) => {
    if (data) {
      downloadFile(data.downloadLink, this.downloadCallback);
      trackEvent('CAT_REPORT', {
        prefix: '',
        name: 'CLICK',
        suffix: 'download report bi-weekly',
      }, {
        prefix: '',
        name: 'CLICK',
        suffix: 'download report successfully',
      });
    } else {
      this.setState({ downloading: false });
    }
  };

  downloadCallback = (isError) => {
    this.setState({ downloading: false });

    if (isError) {
      store.dispatch(notifyFailure('contact.formFeedback.downloadFailed'), 30);
    }
  };
  */
  render() {
    // --- Commented as part of GB-35023 - remove comment to use the download notifications
    // const { t, date } = this.props;

    return (
      <nav className="notification-bar">
        <a
          className="brand"
          href="https://www.ginkgobiosecurity.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="container__image"
            src={cgLogo}
            alt="Ginkgo Biosecurity: Epidemic Tracking and Data Science"
          />
        </a>
        {/* Commented as part of GB-35023 - remove comment to use the download notifications
          <div className="content-wrapper">
          <h3 className="hidden-xs hidden-sm truncate-text">{t('notificationBar.header_text', {
            date: date
              ? getFormattedDate(new Date(date), 'Month DD, YYYY', false, t)
              : null,
          })}
          </h3>
          <button
            type="button"
            className="visible-xs visible-sm truncate-text btn-link-default"
            title="Download Report"
            onClick={this.downloadReportMobile}
          >{t('notificationBar.header_text_mobile', {
            date: date
              ? getFormattedDate(new Date(date), 'Month DD, YYYY', false, t)
              : null,
          })}
          </button>
          <button
            type="button"
            disabled={this.state.downloading}
            className={
              `btn btn-primary btn-primary-outline hidden-xs hidden-sm ${this.state.downloading
                ? 'is-loading'
                : ''}
              `}
            onClick={this.downloadReport}
          >
            {t('notificationBar.download_btn')}
          </button>
        </div> */}
      </nav>
    );
  }
}

export default withNamespaces()(NotificationBar);
