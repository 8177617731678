import React from 'react';
import Checkbox from 'rc-checkbox';
import SelectFactory from '../../SelectFactory/SelectFactory';

const Input = (props) => {
  let inputElement = null;
  let validationError = null;
  let defaultInputClass = 'full-width form-control input-md';
  const defaultSeparatorClass = 'extra-small-margin-bottom padding-top';
  let defaultSelectClass = 'single-select full-width-form-select';
  const isError = props.invalid && props.shouldValidate && props.touched;

  if (isError) {
    validationError = <span className="help-block has-error">{props.errorMessage}</span>;
    defaultInputClass = `${defaultInputClass} has-error`;
    defaultSelectClass = `${defaultSelectClass} has-error`;
  }

  switch (props.elementType) {
    case ('input'):
      inputElement = (
        <input
          id={props.id}
          className={`${defaultInputClass} ${props.elementConfig.class}`}
          {...props.elementConfig}
          value={props.value}
          onBlur={props.changed}
          onChange={props.changed}
        />
      );
      break;
    case ('textarea'):
      inputElement = (
        <textarea
          id={props.id}
          className={`${defaultInputClass} textarea-no-resize ${props.elementConfig.class}`}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case ('checkbox'):
      inputElement = (
        <label className={`checkbox ${isError ? props.elementConfig.class : ''}`} htmlFor={props.id}>
          <Checkbox
            id={props.id}
            checked={props.value}
            onChange={props.changed}
            disabled={props.elementConfig.disabled}
          />
          {props.elementConfig.label}
          {props.labelSuffix ? <i style={{ fontWeight: 'normal' }}>{props.labelSuffix}</i> : null}
        </label>
      );
      break;
    case ('select'):
      inputElement = (
        <SelectFactory
          className={`${defaultSelectClass} ${props.elementConfig.class}`}
          classNamePrefix="react-select"
          {...props.elementConfig}
          firstOptionSelectOnClear
          onRef={props.onRefCallback}
          onUpdate={props.changed}
        />
      );
      break;
    case ('labelSeparator'):
      inputElement = (
        <h5 className={`${defaultSeparatorClass} ${props.elementConfig.class}`}>
          {props.elementConfig.label}
        </h5>
      );
      break;
    default:
      inputElement = (
        <input
          id={props.id}
          className={`${defaultInputClass} ${props.elementConfig.class}`}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return (
    <React.Fragment>
      {!props.hideLabel ? (<h5 className="extra-small-margin-bottom">{props.elementConfig.label}{props.labelSuffix ? <i style={{ fontWeight: 'normal' }}>{props.labelSuffix}</i> : ''}</h5>) : null }
      {!props.hidden ? inputElement : null}
      {!props.hidden ? validationError : null}
    </React.Fragment>
  );
};

export default Input;
