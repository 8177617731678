import React from 'react';

const PulsatingDot = (size) => {
  const bubbleSize = size || 16;

  const smallPulsateCircle = bubbleSize * 4;
  const smallPulsateCirclePos = -(smallPulsateCircle / 2 - bubbleSize / 2);

  const largePulsateCircle = bubbleSize * 8;
  const largePulsateCirclePos = -(largePulsateCircle / 2 - bubbleSize / 2);

  const styles = {
    pulse: {
      small: {
        width: `${smallPulsateCircle}px`,
        height: `${smallPulsateCircle}px`,
        left: `${smallPulsateCirclePos}px`,
        top: `${smallPulsateCirclePos}px`,
      },
      large: {
        width: `${largePulsateCircle}px`,
        height: `${largePulsateCircle}px`,
        left: `${largePulsateCirclePos}px`,
        top: `${largePulsateCirclePos}px`,
      },
    },
    bubble: {
      width: `${size}px`,
      height: `${size}px`,
    },
  };

  return (
    <div className="pulse_holder">
      <span className="pulse_marker">
        <span style={styles.pulse.small} />
      </span>
      <span className="pulse_bubble" style={styles.bubble} />
    </div>
  );
};

export default PulsatingDot;
