import React, { Component } from 'react';
import Select from 'react-select';
import {
  DropdownIndicator,
  Placeholder,
  LoadingIndicator,
  ClearIndicator,
  Option,
  MenuList,
} from './SelectCustomComponents';

export default class SelectFactory extends Component {
  state = {
    selectedOption: '',
  };

  componentDidMount() {
    this.setState({ selectedOption: this.props.defaultOption });
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.firstOptionSelectOnClear) {
      return {
        selectedOption: nextProps.defaultOption,
      };
    }

    return null;
  }

  resetDropdown = () => {
    this.select.select.setValue(null);
  };

  handleItemSelectChange = (option) => {
    this.select.blur();
    if (this.props.firstOptionSelectOnClear) {
      const value = option === null ? this.props.allPathogensOption
        : { value: option.value, label: option.label };
      this.setState({ selectedOption: value });
    } else if (option) {
      const obj = { value: option.value, label: option.label };
      this.setState({ selectedOption: obj });
    } else {
      this.setState({ selectedOption: '' });
    }

    // remove focus after the new option has been added and is still focused
    setTimeout(() => {
      if (this.select) {
        this.select.blur();
      }
    }, 100);

    this.props.onUpdate(option);
  };

  refCallback = ref => (this.select = ref);

  onChangeCallBack = opt => this.handleItemSelectChange(opt);

  render() {
    const {
      isSearchable, classNamePrefix, isClearable,
      placeholder, isLoading, isDisabled, options, className, disabledTooltipText,
    } = this.props;
    const { selectedOption } = this.state;

    const components = {
      DropdownIndicator,
      Placeholder,
      LoadingIndicator,
      ClearIndicator,
      Option,
      MenuList,
    };

    return (
      <div>
        <Select
          ref={this.refCallback}
          disabledTooltipText={disabledTooltipText}
          value={selectedOption}
          className={className}
          options={options}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isSearchable={isSearchable}
          onChange={this.onChangeCallBack}
          isClearable={isClearable}
          components={components}
          placeholder={placeholder}
          classNamePrefix={classNamePrefix}
        />
      </div>
    );
  }
}

SelectFactory.defaultProps = {
  onUpdate: () => ({}),
  isSearchable: false,
  isLoading: false,
  isDisabled: false,
  isClearable: false,
  classNamePrefix: 'react-select',
  className: 'single-select',
};
