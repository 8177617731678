import React from 'react';
import { withNamespaces } from 'react-i18next';
import { TooltipDesktop, TooltipMobile } from '../../Tooltip';

const LatestDataLegend = ({ t }) => (
  <div className="mb-legend-box">
    <div className="title">
      <span className="latest-update">{t('mapbox.legends.latestData.title')}</span>
      <div className="info-box">
        <TooltipDesktop
          theme="legend"
          className="hidden-xs hidden-sm"
          content={t('infoTooltip.legend.latest_data_update')}
          placement="right-end"
        >
          <span className="mb-icon mb-icon-info-icon" />
        </TooltipDesktop>
        <TooltipMobile
          theme="legend"
          className="visible-xs visible-sm"
          content={t('infoTooltip.legend.latest_data_update')}
        >
          <span className="mb-icon mb-icon-info-icon" />
        </TooltipMobile>
      </div>
    </div>
  </div>
);

export default withNamespaces()(LatestDataLegend);
