import { combineReducers } from 'redux';
import mapReducers from './map-reducers';
import pathogenReducers from './pathogen-reducers';
import globalReducers from './global-reducers';
import notificationReducers from './notification-reducer';
import formReducers from './form-reducers';

export default combineReducers({
  mapReducers,
  pathogenReducers,
  globalReducers,
  notificationReducers,
  formReducers,
});
