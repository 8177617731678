import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import * as Constants from '../../constants';

const ModalDialog = props => (
  <Modal
    isOpen={props.isOpen}
    className={`modal-content ${props.type ? props.type : ''}`}
    overlayClassName="modal-overlay"
    contentLabel={props.title}
    shouldCloseOnOverlayClick={false}
    ariaHideApp={false}
  >
    { (props.title)
      ? (
        <div className="modal-header">
          <div className="d-inline-flex">
            { props.showBackButton
              ? (
                <button type="button" className="close back" onClick={props.goBack}>
                  <span className="mb-icon mb-icon-back padding-right-small" />
                </button>
              )
              : null}
            { props.type === Constants.MODAL_TYPE.ERROR
              ? <span className="mb-icon mb-icon-error" />
              : null}
            <h4 className="modal-title">{props.title}</h4>
            {!props.hideCloseButton
              ? (
                <button type="button" className="close" onClick={props.closeModal}>
                  <span className="mb-icon mb-icon-dismiss" />
                </button>
              )
              : null}
          </div>
        </div>
      ) : null }
    <div className={classNames('modal-body', props.modalClass)}>
      {props.children}
    </div>
  </Modal>
);

export default ModalDialog;
