import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import store from '../../redux/store';
import SelectFactory from '../SelectFactory/SelectFactory';
import { trackEvent } from '../../analytics';
import pipe, { isMobileView } from '../../utils';
import {
  setSelectedPathogenProfile,
  clearSelectedPathogenProfile,
} from '../../redux/actions/pathogen-actions';
import PathogenProfileDetails from './PathogenProfileDetails';

class PathogenProfiles extends React.Component {
    pathogensList = this.props.pathogens
      .filter(pathogen => pathogen.hasProfile).map((pathogen, i) => ({
        value: pathogen.pathogenName,
        label: pathogen.pathogenName,
        index: i,
      }));

    componentWillUnmount() {
      clearSelectedPathogenProfile();
    }

    onChangeCallBack = (value) => {
      // add value to store
      if (value) {
        store.dispatch(setSelectedPathogenProfile(value.value));
        trackEvent('CAT_SIDEBAR', {
          prefix: '',
          name: 'CHANGE',
          suffix: `${value.label} profile to display`,
        }, {
          prefix: '',
          name: 'SELECT',
          suffix: 'pathogen to display details',
        });
      } else {
        clearSelectedPathogenProfile();
        trackEvent('CAT_SIDEBAR', {
          prefix: '',
          name: 'RESET',
          suffix: 'pathogen profile',
        }, {
          prefix: '',
          name: 'RESET',
          suffix: 'the profiles details',
        });
      }
    };

    render() {
      const { t, selectedPathogenProfile } = this.props;
      const defaultPathogen = selectedPathogenProfile ? {
        value: selectedPathogenProfile.pathogenName,
        label: selectedPathogenProfile.pathogenName,
      } : '';
      const selectPlaceholder = `Search Profile (${this.pathogensList.length})`;
      return (
        <>
          <div data-testid="pathogen-profiles-comp" className="padding-left padding-right padding-bottom">
            <p className="sub-title extra-small-margin-bottom">{t('home.sidePanelTitles.pathogenLabel')}</p>
            <SelectFactory
              options={this.pathogensList}
              isSearchable={!isMobileView()}
              isClearable
              defaultOption={defaultPathogen}
              onUpdate={this.onChangeCallBack}
              firstOptionSelectOnClear
              placeholder={selectPlaceholder}
              className="single-select no-bottom-margin"
            />
          </div>
          <PathogenProfileDetails pathogenDetails={selectedPathogenProfile} />
        </>
      );
    }
}
function mapStyleStateToProps(state) {
  return {
    selectedPathogenProfile: state.pathogenReducers.selectedPathogenProfile,
    pathogens: state.pathogenReducers.pathogensList,
  };
}

export default pipe(withNamespaces(), connect(mapStyleStateToProps))(PathogenProfiles);
