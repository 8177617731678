import React from 'react';
import { components } from 'react-select';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TooltipDesktop } from '../Tooltip';
import { isMobileView } from '../../utils';

export const LoadingIndicator = () => <i className="mb-icon-spinner" />;

export const Placeholder = props => (
  <components.Placeholder {...props} />
);

export const ClearIndicator = props => (
  components.ClearIndicator && (
    <components.ClearIndicator {...props}>
      <i className="mb-icon-dismiss" />
    </components.ClearIndicator>
  )
);

export const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <i className={props.selectProps.menuIsOpen ? 'mb-icon-arrow-up' : 'mb-icon-arrow-down'} />
    </components.DropdownIndicator>
  )
);

export const Option = props => components.Option && (
  <div>
    <TooltipDesktop
      content={props.selectProps.disabledTooltipText}
      className={`small-tooltip ${!props.isDisabled ? 'hide' : ''}`}
    >
      <div>
        <components.Option {...props} />
      </div>
    </TooltipDesktop>

    <div className="position-relative visible-xs visible-sm">
      <components.Option {...props} />
    </div>
  </div>
);

export const MenuList = class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollContainer: false,
    };
  }

  changeScrollPos = (c) => {
    if (!this.state.scrollContainer) {
      const containerScroll = c;
      const optIndex = this.props.selectProps.options
        .filter(v => (v.value === this.props.selectProps.value.value));
      if (optIndex.length) {
        containerScroll.scrollTop = (optIndex[0].index * 30);
      }
      this.setState({ scrollContainer: c });
    }
  };

  render() {
    return isMobileView()
      ? (
        <div className={`${this.props.selectProps.classNamePrefix}__menu-list overflow-y-scroll`}>
          {this.props.children}
        </div>
      )
      : (
        <div className={`${this.props.selectProps.classNamePrefix}__menu-list`}>
          <PerfectScrollbar
            option={{ suppressScrollX: true }}
            onYReachStart={container => this.changeScrollPos(container)}
          >
            {this.props.children}
          </PerfectScrollbar>
        </div>
      );
  }
};
