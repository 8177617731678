import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import pipe from '../utils';
import InfoCircle from '../components/InfoCircle/InfoCircle';
import store from '../redux/store';
import { setMapData } from '../redux/actions/map-actions';

/**
 * Container for the info circle
 */
class InfoCircleContainer extends React.Component {
  componentWillUnmount() {
    store.dispatch(setMapData(null));
  }

  render() {
    const { data, selectedPathogen, pathogens } = this.props;
    const info = {
      eventsNumber: (data && data.totalEventsNumber) || 0,
      isPathogenSelected: !!(selectedPathogen && selectedPathogen.value),
      pathogensNo: pathogens.filter(pathogen => pathogen.isNRT).length,
    };

    return (
      data && (
      <div className="info-circle">
        <InfoCircle infoCircle={info} />
      </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.mapReducers.data,
    pathogens: state.pathogenReducers.pathogensList,
    selectedPathogen: state.pathogenReducers.selectedPathogen,
  };
}
export default pipe(withNamespaces(), connect(mapStateToProps))(InfoCircleContainer);
