import {
  USER_SYSTEM_NOTIFICATION,
  USER_SYSTEM_NOTIFICATION_CLEAR,
} from '../../constants/action-types';

const defaultState = {
  userNotifications: [],
  lastKey: 0,
};

export default function notificationReducers(state = defaultState, action) {
  switch (action.type) {
    case USER_SYSTEM_NOTIFICATION: {
      return {
        ...state,
        userNotifications: state.userNotifications.concat(
          [{ ...action.payload, lastKey: state.lastKey }],
        ),
        lastKey: state.lastKey + 1,
      };
    }
    case USER_SYSTEM_NOTIFICATION_CLEAR: {
      return {
        ...state,
        userNotifications: state.userNotifications.filter(n => action.index !== n.lastKey),
      };
    }
    default:
      return state;
  }
}
