export const heatMapColors = {
  default: ['#2A2A4B', '#3A3A5E', '#4B4B6B', '#575773', '#6B6B82', '#77778B', '#9A9AAF', '#1D1D3F'],
  hovered: ['#CFCFE4', '#CFCFE4', '#CFCFE4', '#CFCFE4', '#CFCFE4', '#CFCFE4', '#CFCFE4', '#CFCFE4'],
};

export const getMapTooltipPosition = (mapContainer, tooltipContainer, mouseEvent) => {
  const styleProps = {
    left: 'auto',
    right: 'auto',
    top: 'auto',
    bottom: 'auto',
  };
  const arrowPos = {
    vertical: 'top',
    horizontal: 'left',
  };

  const margins = 20;

  if (mouseEvent.clientX + tooltipContainer.clientWidth + margins > mapContainer.clientWidth) {
    styleProps.right = `${mapContainer.clientWidth - mouseEvent.clientX - margins}px`;
    arrowPos.horizontal = 'right';
  } else {
    styleProps.left = `${mouseEvent.clientX - margins / 2}px`;
  }

  if (mouseEvent.clientY + tooltipContainer.clientHeight > mapContainer.clientHeight) {
    styleProps.top = `${mouseEvent.clientY - tooltipContainer.clientHeight - margins * 2 - margins / 2}px`;
    arrowPos.vertical = 'bottom';
  } else {
    styleProps.top = `${mouseEvent.clientY - margins / 2}px`;
  }

  return {
    styleProps,
    arrowPos,
  };
};

export const mapDefaults = {
  center: [-15.4, 26],
  maxZoom: 11,
  minZoom: 1,
  zoom: 1,
};
