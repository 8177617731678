import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Notification from './Notification/Notification';
import pipe from '../utils';

const NotificationsBar = ({
  t, loading, userNotifications,
}) => (
  <React.Fragment>
    { !loading && userNotifications && (
    <div id="notification-bar" className="col-md-6 col-xs-12 col-md-offset-3 col-xs-offset-0">
      <div className="notification-container">
        <div className="collapse in notification-body">
          {userNotifications.map(({
            message, type, labelBtn, callbackBtn, duration, lastKey,
          }) => (
            <Notification
              key={lastKey}
              type={type}
              index={lastKey}
              message={t(message)}
              duration={duration}
              labelBtn={t(labelBtn)}
              onClicked={callbackBtn}
            />
          ))}
        </div>
      </div>
    </div>
    )
    }
  </React.Fragment>
);

function mapStateToProps(state) {
  return {
    loading: state.globalReducers.loading,
    userNotifications: state.notificationReducers.userNotifications,
  };
}

export default pipe(withNamespaces(), connect(mapStateToProps))(NotificationsBar);
